import { AnyAction, Dispatch } from "redux"

export type FetchActionsT = {
	dispatch: Dispatch
	port: string
	path: string
	success: <A>(e: A) => AnyAction
	failure: (e: string) => AnyAction
	method?: string
	body?: any
}

export const READ_MODEL_REQ = "READ_MODEL_REQ"
export const READ_MODEL_SUCCESS = "READ_MODEL_SUCCESS"
export const READ_MODEL_FAIL = "READ_MODEL_FAIL"
export const POST_REQ = "POST_REQ"
export const POST_SUCCESS = "POST_SUCCESS"
export const POST_FAIL = "POST_FAIL"
export const POST_RESET = "POST_RESET"
export const LOGIN_REQ = "LOGIN_REQ"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAIL = "LOGIN_FAIL"
export const LOGIN_RESET = "LOGIN_RESET"
export const REGISTER_REQ = "REGISTER_REQ"
export const REGISTER_SUCCESS = "REGISTER_SUCCESS"
export const REGISTER_FAIL = "REGISTER_FAIL"
export const REGISTER_RESET = "REGISTER_RESET"

export const PROJECT_REQ = "PROJECT_REQ"
export const PROJECT_SUCCESS = "PROJECT_SUCCESS"
export const PROJECT_FAIL = "PROJECT_FAIL"
export const PROJECT_RESET = "PROJECT_RESET"
export const PROJECT_CREATE_TEMPLATE_SUCCESS = "PROJECT_CREATE_TEMPLATE_SUCCESS"
export const PROJECT_CREATE_TEMPLATE_FAILURE = "PROJECT_CREATE_TEMPLATE_FAILURE"

export const COMPANIES_REQUEST = "COMPANIES_REQUEST"
export const COMPANIES_SUCCESS = "COMPANIES_SUCCESS"
export const COMPANIES_FAILURE = "COMPANIES_FAILURE"
export const COMPANY_SUCCESS = "COMPANY_SUCCESS"

export const COUNTRIES_REQUEST = "COUNTRIES_REQUEST"
export const COUNTRIES_SUCCESS = "COUNTRIES_SUCCESS"
export const COUNTRIES_FAILURE = "COUNTRIES_FAILURE"

export const MEMBERSHIPS_SUCCESS = "MEMBERSHIPS_SUCCESS"
export const MEMBERSHIPS_FAILURE = "MEMBERSHIPS_FAILURE"
export const MEMBERSHIPS_REQUEST = "MEMBERSHIPS_REQUEST"
export const MEMBERSHIP_SUCCESS = "MEMBERSHIP_SUCCESS"

export const FAQS_REQUEST = "FAQS_REQUEST"
export const FAQS_SUCCESS = "FAQS_SUCCESS"
export const FAQS_FAILURE = "FAQS_FAILURE"
export const FAQ_SUCCESS = "FAQ_SUCCESS"

export const SERVICES_TYPES_REQUEST = "SERVICES_TYPES_REQUEST"
export const SERVICES_TYPES_SUCCESS = "SERVICES_TYPES_SUCCESS"
export const SERVICES_TYPES_FAILURE = "SERVICES_TYPES_FAILURE"
export const SERVICE_TYPE_SUCCESS = "SERVICE_TYPE_SUCCESS"

export const USERS_REQUEST = "USERS_REQUEST"
export const USERS_SUCCESS = "USERS_SUCCESS"
export const USERS_FAILURE = "USERS_FAILURE"
export const USER_SUCCESS = "USER_SUCCESS"
