import { COUNTRIES_SUCCESS, COUNTRIES_FAILURE, COUNTRIES_REQUEST } from "../types"

const initialState = {
	loading: false,
	list: [],
	error: "",
}

export const countriesReducer = <A>(
	state = initialState,
	action: {
		type: typeof COUNTRIES_REQUEST | typeof COUNTRIES_SUCCESS | typeof COUNTRIES_FAILURE
		payload: A
	}
): any => {
	switch (action.type) {
		case COUNTRIES_REQUEST: {
			return {
				...state,
				loading: true,
			}
		}
		case COUNTRIES_SUCCESS: {
			return {
				loading: false,
				list: action.payload,
				error: "",
			}
		}
		case COUNTRIES_FAILURE: {
			return {
				loading: false,
				list: [],
				error: action.payload,
			}
		}
		default:
			return state
	}
}
