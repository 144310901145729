import { FC } from "react"
import { TInputFieldProps } from "./Fields.types"

export const Input: FC<TInputFieldProps> = ({ errors, touched, handleBlur, handleChange, label, name, values, type = "text", disabled }) => (
	<>
		<label>{label}</label>
		<input onBlur={handleBlur} disabled={disabled} onChange={handleChange} type={type} name={name} className={errors[name] && touched[name] && "error"} value={values[name] ?? ""} />
		{errors[name] && touched[name] && <div className="errorMessage">{errors[name]}</div>}
	</>
)
