import { FetchActionsT } from "./types"

export const fetchActions = ({ dispatch, port, path, success, failure, method, body }: FetchActionsT): any =>
	fetch(process.env.REACT_APP_API_URL + port + "/" + path, {
		method,
		headers: {
			accept: "application/json",
			"Content-Type": "application/json",
			Authorization: "Bearer " + localStorage.getItem("token"),
		},
		body: body ? JSON.stringify(body) : null,
	})
		.then(response => response.json())
		.then(data => {
			if (data.name === "InvalidTokenError") localStorage.removeItem("token")
			else dispatch(success(data))
		})
		.catch(err => dispatch(failure(err)))
