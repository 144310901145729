import { Dispatch } from "redux"

import { MEMBERSHIPS_SUCCESS, MEMBERSHIPS_FAILURE, MEMBERSHIPS_REQUEST, MEMBERSHIP_SUCCESS } from "../types"
import { fetchActions } from "../fetch"

export const membershipsRequest = (): { type: typeof MEMBERSHIPS_REQUEST } => ({
	type: MEMBERSHIPS_REQUEST,
})

export const membershipsSuccess = <A>(data: A): { type: typeof MEMBERSHIPS_SUCCESS; payload: A } => ({
	type: MEMBERSHIPS_SUCCESS,
	payload: data,
})

export const membershipSuccess = (): { type: typeof MEMBERSHIP_SUCCESS } => ({ type: MEMBERSHIP_SUCCESS })

export const membershipsFailure = (error: string): { type: typeof MEMBERSHIPS_FAILURE; payload: string } => ({
	type: MEMBERSHIPS_FAILURE,
	payload: error,
})

export const membershipsReadModel =
	(path: string): any =>
	(dispatch: Dispatch) => {
		dispatch(membershipsRequest())
		fetchActions({
			dispatch,
			port: process.env.REACT_APP_PORT_BACKOFFICE as string,
			path,
			success: membershipsSuccess,
			failure: membershipsFailure,
			method: "get",
		})
	}

export const membershipsPost =
	(path: string, body: any): any =>
	(dispatch: Dispatch) => {
		dispatch(membershipsRequest())
		fetchActions({
			dispatch,
			port: process.env.REACT_APP_PORT_BACKOFFICE as string,
			path,
			success: membershipSuccess,
			failure: membershipsFailure,
			method: "post",
			body,
		})
	}
