export const TableHead = ({ data }: { data: any }) => {
	//! Pending handle generic types
	if (!data) return null

	return (
		<thead>
			<tr>
				{data?.map((th: string, index: number) => (
					<th key={index}>{th}</th>
				))}
			</tr>
		</thead>
	)
}
