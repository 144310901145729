import { FC } from "react"
import { motion } from "framer-motion"

import { PageTransitionPropsT } from "./entities"
import { pageTransitionStyle } from "./styles"

export const PageTransition: FC<PageTransitionPropsT> = ({ children }) => (
	<motion.div className="page" initial="out" animate="in" exit="out" variants={pageTransitionStyle}>
		{children}
	</motion.div>
)
