import { useStore } from "../store"

export const useFilterOptions = () => {
	const { list } = useStore()

	const types = list.reduce((prev: string[], cur: any) => (prev.indexOf(cur.type) < 0 ? prev.concat([cur.type]) : prev), [])
	const categories = list.reduce((prev: string[], cur: any) => (prev.indexOf(cur.category) < 0 ? prev.concat([cur.category]) : prev), [])

	return [
		{
			tag: "type",
			es: "tipo",
			view: "faq",
			options: types.map((item: string) => ({
				label: item,
				value: item,
			})),
		},
		{
			tag: "category",
			es: "categoría",
			view: "faq",
			options: categories.map((item: string) => ({
				label: item,
				value: item,
			})),
		},
	]
}
