import { useState } from "react"

import { SearchInputT, useSearchPropsT, useSearchT } from "../entities"

export const useSearch = (handleSearch: useSearchPropsT): useSearchT => {
	const [term, setTerm] = useState<string>("")

	const search = (event: SearchInputT) => {
		const { value } = event.target
		handleSearch(value)
		setTerm(value)
	}

	const reset = () => {
		handleSearch("")
		setTerm("")
	}

	return { reset, term, search }
}
