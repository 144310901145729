import { FC } from "react"

import { TYouTubeProps } from "./YouTube.types"

export const YouTube: FC<TYouTubeProps> = ({ path }) => (
   <iframe
      width="853"
      height="480"
      src={`https://www.youtube.com/embed/${path}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
   />
)
