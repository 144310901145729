import { StylesConfig } from "react-select"

export const styles: StylesConfig<any, true> = {
	container: styles => ({
		...styles,
		width: 282,
		border: 0,
		marginLeft: 15,
	}),
	control: (styles, state) => ({
		...styles,
		borderWidth: 0.5,
		borderColor: "#e9e9e9",
		borderRadius: 8,
		fontSize: 14,
		width: 282,
		boxShadow: "0 0 0",
		backgroundColor: state.isFocused ? "#e9e9e9" : "white",
		cursor: "pointer",
		"&:hover": {
			borderColor: "#f0f0f5",
		},
	}),
	multiValue: styles => ({
		...styles,
		backgroundColor: "#ff452b",
		borderRadius: 5,
		"> div": {
			color: "white",
		},
	}),
	dropdownIndicator: styles => ({
		...styles,
		color: "#ff452b",
	}),
}
