import { useState } from "react"
import { NavLink } from "react-router-dom"
import { useSelector } from "react-redux"

import { RootState } from "../../store/store"
import "./styles/index.css"
import { IconMenu, IconBack } from "../../components/commons/Icons"

export const Aside = () => {
	const [showMenu, setShowMenu] = useState<boolean>(false)
	const { list: companies } = useSelector((state: RootState) => state.companiesReducer)

	return (
		<aside id="admin" className={showMenu ? "showMenu" : ""}>
			{!showMenu && (
				<>
					<IconMenu onClick={() => setShowMenu(true)} />
					<span>MENÚ</span>
				</>
			)}
			{showMenu && <IconBack onClick={() => setShowMenu(false)} />}
			<ul>
				<span>CONFIGURACIÓN</span>
				{companies[0]?.departments?.length ? (
					<NavLink to="/cities-settings" className={({ isActive }) => (isActive ? "active" : "")}>
						Ciudades
					</NavLink>
				) : null}
				{companies.length ? (
					<NavLink to="/departments-settings" className={({ isActive }) => (isActive ? "active" : "")}>
						Departamentos
					</NavLink>
				) : null}
				<NavLink to="/membership-settings" className={({ isActive }) => (isActive ? "active" : "")}>
					Membresía X
				</NavLink>
				<NavLink to="/services-types-settings" className={({ isActive }) => (isActive ? "active" : "")}>
					Tipos de servicios
				</NavLink>
				<NavLink to="/faq" className={({ isActive }) => (isActive ? "active" : "")}>
					Preguntas frecuentes
				</NavLink>
				<span>EMPRESAS</span>
				<NavLink to="/companies" className={({ isActive }) => (isActive ? "active" : "")}>
					Empresas
				</NavLink>
				<NavLink to="/users-profiles-companies" className={({ isActive }) => (isActive ? "active" : "")}>
					Usuarios en empresas
				</NavLink>
				<NavLink to="/companies-membership" className={({ isActive }) => (isActive ? "active" : "")}>
					Membresías de empresas
				</NavLink>
				<span>PAGOS</span>
				<NavLink to="/membership-pay" className={({ isActive }) => (isActive ? "active" : "")}>
					Pago de membresía X
				</NavLink>
				<span>USUARIOS</span>
				<NavLink to="/users-profiles-back-office" className={({ isActive }) => (isActive ? "active" : "")}>
					Usuarios Back Office
				</NavLink>
			</ul>
		</aside>
	)
}
