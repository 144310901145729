import { useState, useEffect } from "react"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import "../../admin.css"
import { tableHeader, title, titleModal, emptyTable } from "./data"
import { crudTypeT } from "./entities"
import { useForm } from "./userStories"
import { THead, TBody, Aside, Add, Modal, Form, Empty } from "./components"
import { useStore, servicesTypesReadModel } from "./store"

export const ServicesTypes = () => {
	const [openModal, setOpenModal] = useState(false)
	const [titleModalType, setTitleModalType] = useState<crudTypeT>("none")
	const { dispatch, list, path, newGet } = useStore()
	const { formik, edit, create } = useForm()

	useEffect(() => dispatch(servicesTypesReadModel(path)), [dispatch, newGet])

	useEffect(() => disableModal(), [list])

	const enableModal = (type: crudTypeT, id?: string) => {
		setOpenModal(true)
		setTitleModalType(type)
		if (type === "edit" && id) edit(id)
		else if (type === "create") create()
	}

	const disableModal = () => setOpenModal(false)

	return (
		<>
			<main id="admin">
				<Aside />
				<section>
					<h1>{title}</h1>
					{list.length ? (
						<table>
							<THead data={tableHeader} />
							<TBody list={list} edit={(id: string) => enableModal("edit", id)} />
						</table>
					) : (
						<Empty text={emptyTable} />
					)}
				</section>
			</main>
			<Add onClick={() => enableModal("create")} />
			<Modal open={openModal} onClose={disableModal}>
				{titleModalType !== "none" && <h1>{titleModal[titleModalType]}</h1>}
				<Form formik={formik} />
			</Modal>
			<ToastContainer />
		</>
	)
}
