import { Dispatch } from "redux"

import { PROJECT_REQ, PROJECT_SUCCESS, PROJECT_FAIL, PROJECT_RESET, PROJECT_CREATE_TEMPLATE_SUCCESS, PROJECT_CREATE_TEMPLATE_FAILURE } from "../types"
import { fetchActions } from "../fetch"

export const projectRequest = (): { type: typeof PROJECT_REQ } => ({
	type: PROJECT_REQ,
})

export const projectSuccess = <A>(data: A): { type: typeof PROJECT_SUCCESS; payload: A } => ({
	type: PROJECT_SUCCESS,
	payload: data,
})

export const projectFailure = (error: string): { type: typeof PROJECT_FAIL; payload: string } => ({
	type: PROJECT_FAIL,
	payload: error,
})

export const projectCreateTemplateSuccess = <A>(data: A): { type: typeof PROJECT_CREATE_TEMPLATE_SUCCESS; payload: A } => ({
	type: PROJECT_CREATE_TEMPLATE_SUCCESS,
	payload: data,
})

export const projectCreateTemplateFailure = (error: string): { type: typeof PROJECT_CREATE_TEMPLATE_FAILURE; payload: string } => ({
	type: PROJECT_CREATE_TEMPLATE_FAILURE,
	payload: error,
})

export const projectReset = (): { type: typeof PROJECT_RESET } => ({
	type: PROJECT_RESET,
})

export const projectGet =
	(path: string): any =>
	(dispatch: Dispatch) => {
		dispatch(projectRequest())
		fetchActions({
			dispatch,
			port: process.env.REACT_APP_PORT_BACKEND as string,
			path,
			success: projectSuccess,
			failure: projectFailure,
			method: "get",
		})
	}

export const projectCreateTemplate =
	<A>(path: string, body: A): any =>
	(dispatch: Dispatch) => {
		const uuid = path.split("/")[1]
		dispatch(projectRequest())
		fetchActions({
			dispatch,
			port: process.env.REACT_APP_PORT_BACKEND as string,
			path,
			success: projectCreateTemplateSuccess,
			failure: projectCreateTemplateFailure,
			method: "POST",
			body,
		})
	}
