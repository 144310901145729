import { FAQS_SUCCESS, FAQS_FAILURE, FAQS_REQUEST, FAQ_SUCCESS } from "../types"

const initialState = {
	loading: false,
	list: [],
	error: "",
	newGet: false,
}

export const faqReducer = <A>(
	state = initialState,
	action: {
		type: typeof FAQS_REQUEST | typeof FAQS_SUCCESS | typeof FAQS_FAILURE | typeof FAQ_SUCCESS
		payload: A
	}
): any => {
	switch (action.type) {
		case FAQS_REQUEST: {
			return {
				...state,
				loading: true,
				newGet: false,
			}
		}
		case FAQS_SUCCESS: {
			return {
				loading: false,
				list: action.payload,
				error: "",
				newGet: false,
			}
		}
		case FAQ_SUCCESS: {
			return {
				loading: false,
				list: [],
				error: "",
				newGet: true,
			}
		}
		case FAQS_FAILURE: {
			return {
				loading: false,
				list: [],
				error: action.payload,
				newGet: false,
			}
		}
		default:
			return state
	}
}
