import { PROJECT_FAIL, PROJECT_REQ, PROJECT_SUCCESS, PROJECT_RESET, PROJECT_CREATE_TEMPLATE_FAILURE, PROJECT_CREATE_TEMPLATE_SUCCESS } from "../types"

const initialState = {
	loading: false,
	list: [],
	error: "",
}

export const projectReducer = <A>(
	state = initialState,
	action: {
		type: typeof PROJECT_FAIL | typeof PROJECT_REQ | typeof PROJECT_SUCCESS | typeof PROJECT_RESET | typeof PROJECT_CREATE_TEMPLATE_SUCCESS | typeof PROJECT_CREATE_TEMPLATE_FAILURE
		payload: A
	}
): any => {
	switch (action.type) {
		case PROJECT_REQ: {
			return {
				...state,
				loading: true,
			}
		}
		case PROJECT_SUCCESS: {
			return {
				loading: false,
				list: action.payload,
				error: "",
			}
		}
		case PROJECT_CREATE_TEMPLATE_SUCCESS: {
			return {
				loading: false,
				list: action.payload,
				error: "",
			}
		}
		case PROJECT_FAIL: {
			return {
				loading: false,
				list: [],
				error: action.payload,
			}
		}
		case PROJECT_CREATE_TEMPLATE_FAILURE: {
			return {
				loading: false,
				list: [],
				error: action.payload,
			}
		}
		case PROJECT_RESET: {
			return initialState
		}
		default:
			return state
	}
}
