import { Link } from "react-router-dom"

import "./Error404.styles.css"

export const Error404 = () => (
   <main id="error404">
      <img src="img/error.png" alt="404" />
      <p>Esta página no existe!</p>
      <Link to="/">
         <button>Volver al Login</button>
      </Link>
   </main>
)
