import { FC } from "react"
import Select from "react-select"

import { styles } from "./styles"
import { NoOptionsMessage } from "./components"

export const Filters: FC<any> = ({ handleFilteredList, options }) => (
	<div>
		{options?.map((option: any, index: number) => (
			<Select
				components={{ NoOptionsMessage }}
				closeMenuOnSelect={false}
				key={index}
				isMulti
				options={option.options}
				styles={styles}
				onChange={(options: any) => handleFilteredList(options, option.tag, option.view)}
				placeholder={`Filtrar por ${option.es}...`}
			/>
		))}
	</div>
)
