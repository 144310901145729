import { FC } from "react"
import { DeleteOutlined } from "@mui/icons-material"

import { TIconDelete } from "./IconDelete.types"

export const IconDelete: FC<TIconDelete> = ({ clear, name }) => (
	<DeleteOutlined
		fontSize="small"
		className="after"
		style={{ fill: "#00263e" }}
		onClick={() => {
			if (clear && name) clear(name)
		}}
	/>
)
