import * as Yup from "yup"

const validations = {
	position: Yup.number().required("* Campo obligatorio").default(null).nullable(),
	answer: Yup.string().required("* Campo obligatorio").default(null).nullable(),
	question: Yup.string().required("* Campo obligatorio").default(null).nullable(),
	type: Yup.string().required("* Campo obligatorio").default(null).nullable(),
	category: Yup.string().required("* Campo obligatorio").default(null).nullable(),
}

export const validationSchema = () => Yup.object().shape(validations)
