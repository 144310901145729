import { FC } from "react"
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import es from "date-fns/locale/es"

registerLocale("es", es)

export const DateField: FC<{ name: string; formik: any; label: string }> = ({
   name,
   formik,
   label,
}) => (
   <>
      <label>{label}</label>
      <DatePicker
         selected={formik.values[name]}
         name={name}
         onChange={(date: Date) => formik.setFieldValue(name, date)}
         locale="es"
         dateFormat="dd/MM/yyyy"
      />
   </>
)
