import { FC } from "react"
import { TSelectFieldProps } from "./Fields.types"

export const Select: FC<TSelectFieldProps> = ({ errors, touched, handleBlur, handleChange, label, name, values, disabled, options }) => (
	<>
		<label>{label}</label>
		<select onBlur={handleBlur} disabled={disabled} onChange={handleChange} name={name} className={errors[name] && touched[name] && "error"} value={values[name]}>
			<option value="_none">Seleccionar...</option>
			{options.map((option: string) => (
				<option key={option}>{option}</option>
			))}
		</select>
		{errors[name] && touched[name] && <div className="errorMessage">{errors[name]}</div>}
	</>
)
