import { useStore } from "../store"

export const useFilterOptions = () => {
	const { list } = useStore()

	const state = list.reduce((prev: string[], cur: any) => (prev.indexOf(cur.state) < 0 ? prev.concat([cur.state]) : prev), [])
	const membership = list.reduce((prev: string[], cur: any) => (prev.indexOf(cur.memberships[0].name) < 0 ? prev.concat([cur.memberships[0].name]) : prev), [])
	const packages = list.reduce((prev: string[], cur: any) => (prev.indexOf(cur.memberships[0].type) < 0 ? prev.concat([cur.memberships[0].type]) : prev), [])

	return [
		{
			tag: "type",
			es: "paquete",
			options: packages.map((item: string) => ({
				label: item,
				value: item,
			})),
		},
		{
			tag: "name",
			es: "membresía",
			options: membership.map((item: string) => ({
				label: item,
				value: item,
			})),
		},

		{
			tag: "state",
			es: "estado",
			options: state.map((item: string) => ({
				label: item,
				value: item,
			})),
		},
	]
}
