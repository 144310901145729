import { FC } from "react"

import { TElementList } from "../../entities"
import { IconEdit, IconEye } from "../../../../../components/commons/Icons"

export const TableBody: FC<any> = ({ list, edit, view }) => {
	const bodyMap = () => {
		return list?.map((row: TElementList) => {
			return row.memberships.map((membership: any) => (
				<tr key={membership.uuid}>
					<td>{membership.uuid}</td>
					<td>{row.businessName}</td>
					<td>{membership.name}</td>
					<td>{membership.type}</td>
					<td>{membership.durationTime}</td>
					<td>{membership.expirationDate}</td>
					<td>{membership.selfRenewal ? "Si" : "No"}</td>
					<td className="right" title="Editar" onClick={() => edit(row.id, membership.uuid)}>
						<IconEdit />
					</td>
					<td className="right" title="Ver" onClick={() => view(row.id)}>
						<IconEye />
					</td>
				</tr>
			))
		})
	}

	return <tbody>{bodyMap()}</tbody>
}
