import { Dispatch } from "redux"

import { SERVICES_TYPES_SUCCESS, SERVICES_TYPES_FAILURE, SERVICES_TYPES_REQUEST, SERVICE_TYPE_SUCCESS } from "../types"
import { fetchActions } from "../fetch"

export const servicesTypesRequest = (): { type: typeof SERVICES_TYPES_REQUEST } => ({
	type: SERVICES_TYPES_REQUEST,
})

export const servicesTypesSuccess = <A>(data: A): { type: typeof SERVICES_TYPES_SUCCESS; payload: A } => {
	return {
		type: SERVICES_TYPES_SUCCESS,
		payload: data,
	}
}

export const serviceTypeSuccess = () => {
	return {
		type: SERVICE_TYPE_SUCCESS,
	}
}

export const servicesTypesFailure = (error: string): { type: typeof SERVICES_TYPES_FAILURE; payload: string } => ({
	type: SERVICES_TYPES_FAILURE,
	payload: error,
})

export const servicesTypesReadModel =
	(path: string): any =>
	(dispatch: Dispatch) => {
		dispatch(servicesTypesRequest())
		fetchActions({
			dispatch,
			port: process.env.REACT_APP_PORT_BACKOFFICE as string,
			path,
			success: servicesTypesSuccess,
			failure: servicesTypesFailure,
			method: "get",
		})
	}

export const serviceTypePost =
	(path: string, body: any): any =>
	(dispatch: Dispatch) => {
		dispatch(servicesTypesRequest())
		fetchActions({
			dispatch,
			port: process.env.REACT_APP_PORT_BACKOFFICE as string,
			path,
			success: serviceTypeSuccess,
			failure: servicesTypesFailure,
			method: "post",
			body,
		})
	}
