import { Dispatch } from "redux"

import { COUNTRIES_SUCCESS, COUNTRIES_FAILURE, COUNTRIES_REQUEST } from "../types"
import { fetchActions } from "../fetch"

export const countriesRequest = (): { type: typeof COUNTRIES_REQUEST } => ({
	type: COUNTRIES_REQUEST,
})

export const countriesSuccess = <A>(data: A): { type: typeof COUNTRIES_SUCCESS; payload: A } => {
	return {
		type: COUNTRIES_SUCCESS,
		payload: data,
	}
}

export const countriesFailure = (error: string): { type: typeof COUNTRIES_FAILURE; payload: string } => ({
	type: COUNTRIES_FAILURE,
	payload: error,
})

export const countriesReadModel =
	(path: string): any =>
	(dispatch: Dispatch) => {
		dispatch(countriesRequest())
		fetchActions({
			dispatch,
			port: process.env.REACT_APP_PORT_BACKOFFICE as string,
			path,
			success: countriesSuccess,
			failure: countriesFailure,
			method: "get",
		})
	}
