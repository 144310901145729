import { Dispatch } from "redux"

import { FAQS_SUCCESS, FAQS_FAILURE, FAQS_REQUEST, FAQ_SUCCESS } from "../types"
import { fetchActions } from "../fetch"

export const faqsRequest = (): { type: typeof FAQS_REQUEST } => ({
	type: FAQS_REQUEST,
})

export const faqsSuccess = <A>(data: A): { type: typeof FAQS_SUCCESS; payload: A } => {
	return {
		type: FAQS_SUCCESS,
		payload: data,
	}
}

export const faqSuccess = () => {
	return {
		type: FAQ_SUCCESS,
	}
}

export const faqsFailure = (error: string): { type: typeof FAQS_FAILURE; payload: string } => ({
	type: FAQS_FAILURE,
	payload: error,
})

export const faqsReadModel =
	(path: string): any =>
	(dispatch: Dispatch) => {
		dispatch(faqsRequest())
		fetchActions({
			dispatch,
			port: process.env.REACT_APP_PORT_BACKOFFICE as string,
			path,
			success: faqsSuccess,
			failure: faqsFailure,
			method: "get",
		})
	}

export const faqPost =
	(path: string, body: any): any =>
	(dispatch: Dispatch) => {
		dispatch(faqsRequest())
		fetchActions({
			dispatch,
			port: process.env.REACT_APP_PORT_BACKOFFICE as string,
			path,
			success: faqSuccess,
			failure: faqsFailure,
			method: "post",
			body,
		})
	}
