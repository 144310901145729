import { useStore } from "../store"

export const useFilterOptions = () => {
	const { list } = useStore()
	const roles = list.reduce((prev: string[], actual: { rol: string }) => (prev.indexOf(actual.rol) < 0 ? prev.concat([actual.rol]) : prev), [])

	return [
		{
			tag: "rol",
			es: "Rol",
			view: "users",
			options: roles.map((item: string) => ({
				label: item,
				value: item,
			})),
		},
	]
}
