import { useSelector, useDispatch } from "react-redux"

import { RootState } from "../../../../store/store"

export const useStore = (): any => {
	const { list, newGet } = useSelector((state: RootState) => state.companiesReducer)
	const { list: getCountries } = useSelector((state: RootState) => state.countriesReducer)
	const dispatch = useDispatch()
	const path = "companies/readmodel"
	const pathCountries = "countries/readmodel"

	return { list, newGet, dispatch, path, getCountries, pathCountries }
}

export { companiesReadModel, companiesPost, countriesReadModel } from "../../../../store/actions"
