import { FC } from "react"
import { Switch } from "@mui/material"

import "./InputSwitch.styles.css"
import type { TInputSwitchProps } from "./InputSwitch.types"

export const InputSwitch: FC<TInputSwitchProps> = ({ name, label, formik }) => (
   <>
      <Switch
         value={formik.values[name] ?? false}
         checked={formik.values[name] ?? false}
         onChange={() => formik.setFieldValue(name, !formik.values[name])}
         name={name}
         color="secondary"
      />
      <div className="switch-label">{label}</div>
   </>
)
