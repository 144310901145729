import {
   REGISTER_REQ,
   REGISTER_SUCCESS,
   REGISTER_FAIL,
   REGISTER_RESET,
} from "../types"

const initialState = {
   loading: false,
   data: {},
   error: "",
}

export const registerReducer = <A>(
   state = initialState,
   action: {
      type:
         | typeof REGISTER_FAIL
         | typeof REGISTER_REQ
         | typeof REGISTER_SUCCESS
         | typeof REGISTER_RESET
      payload: A
   }
): any => {
   switch (action.type) {
      case REGISTER_REQ: {
         return {
            ...state,
            loading: true,
         }
      }
      case REGISTER_RESET: {
         return initialState
      }
      case REGISTER_SUCCESS: {
         return {
            loading: false,
            data: action.payload,
            error: "",
         }
      }
      case REGISTER_FAIL: {
         return {
            data: {},
            loading: false,
            error: action.payload,
         }
      }
      default:
         return state
   }
}
