import { SERVICES_TYPES_SUCCESS, SERVICES_TYPES_FAILURE, SERVICES_TYPES_REQUEST, SERVICE_TYPE_SUCCESS } from "../types"

const initialState = {
	loading: false,
	list: [],
	error: "",
	newGet: false,
}

export const servicesTypesReducer = <A>(
	state = initialState,
	action: {
		type: typeof SERVICES_TYPES_REQUEST | typeof SERVICES_TYPES_SUCCESS | typeof SERVICES_TYPES_FAILURE | typeof SERVICE_TYPE_SUCCESS
		payload: A
	}
): any => {
	switch (action.type) {
		case SERVICES_TYPES_REQUEST: {
			return {
				...state,
				loading: true,
				newGet: false,
			}
		}
		case SERVICES_TYPES_SUCCESS: {
			return {
				loading: false,
				list: action.payload,
				error: "",
				newGet: false,
			}
		}
		case SERVICE_TYPE_SUCCESS: {
			return {
				loading: false,
				list: [],
				error: "",
				newGet: true,
			}
		}
		case SERVICES_TYPES_FAILURE: {
			return {
				loading: false,
				list: [],
				error: action.payload,
				newGet: false,
			}
		}
		default:
			return state
	}
}
