import { useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import "./Landing.styles.css"
import { RootState } from "../../store/store"
import { projectGet } from "../../store/actions"
import { FormStyled } from "../Project/Tabs/Form.styled"
import { YouTube } from "../../components/commons/YouTube"

export const Landing = () => {
	const { uuid } = useParams()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const { list } = useSelector((state: RootState) => {
		return state.projectReducer
	})

	useEffect(() => {
		dispatch(projectGet(`projects/readmodel/${uuid}`))
	}, [dispatch, uuid])

	if (!list[0]) return null

	const uniquePlaces = Array.from(new Set(list[0].priceList?.map((a: any) => a.type))).map(type => list[0].priceList.find((a: any) => a.type === type))

	return (
		<main className="landing">
			<div>
				<div className="left">
					<h1>{list[0].name}</h1>
				</div>
				<div>
					<button className="back" onClick={() => navigate("/list")}>
						Volver
					</button>
				</div>
			</div>
			<div>
				<div className="left">
					<h3>Descripción</h3>
					<p>{list[0].description}</p>
					{list[0]?.video && <YouTube path={list[0].video.split("=")[1]} />}
				</div>
				<div className="right">
					<h3>Formulario de registro</h3>
					<FormStyled>
						<form>
							<input type="text" placeholder="Nombre" />
							<input type="text" placeholder="Apellido" />
							<input type="number" placeholder="Documento de identidad" />
							<input type="email" placeholder="Correo electrónico" />
							<button>Registrarme</button>
						</form>
					</FormStyled>
					{list[0].subsidies && (
						<>
							<h3>Subsidios disponibles</h3>
							<ul>
								{list[0].subsidies?.map((subsidy: { name: string }) => (
									<li key={subsidy.name}>💰 {subsidy.name}</li>
								))}
							</ul>
						</>
					)}
					{list[0].initialDate && (
						<>
							<h3>Fecha de inicio</h3>
							<h6>📅 {new Date(list[0]?.initialDate).toLocaleDateString()}</h6>
						</>
					)}
					{list[0].endDate && (
						<>
							<h3>Fecha de finalización</h3>
							<h6>📅 {new Date(list[0]?.endDate).toLocaleDateString()}</h6>
						</>
					)}
				</div>
			</div>
			<h2>Información financiera</h2>
			<table>
				{list[0]?.subsideEnabled ? (
					<>
						<thead>
							<tr>
								<th>Tipo</th>
								<th>Valor</th>
								<th>Reserva</th>
								<th>Cuotas</th>
								<th>
									Cuota mensual sugerida
									<br />
									{`De $0 a $${list[0].subsidies[0].rank.min}`}
								</th>
								<th>
									Cuota mensual sugerida
									<br />
									{`Entre $${list[0].subsidies[0].rank.min + 1} y $${list[0].subsidies[0].rank.max}`}
								</th>
								<th>
									Cuota mensual sugerida
									<br />
									{`Más de $0 a $${list[0].subsidies[0].rank.max}`}
								</th>
								<th>Crédito bancario</th>
							</tr>
						</thead>
						<tbody>
							{list[0].priceList.map((price: any, index: number) => (
								<tr key={index}>
									<td>{price.type}</td>
									<td>{`$${price.value}`}</td>
									<td>{`$${price.reserveValue}`}</td>
									<td>{list[0].template.count}</td>
									<td>
										{`$${
											price.value * 0.3 > list[0].subsidies[0].rank.value1
												? (price.value * 0.3 - list[0].subsidies[0].rank.value1) / list[0].template.count
												: list[0].template.min / list[0].template.count
										}`}
									</td>
									<td>
										{`$${
											price.value * 0.3 > list[0].subsidies[0].rank.value2
												? (price.value * 0.3 - list[0].subsidies[0].rank.value2) / list[0].template.count
												: list[0].template.min / list[0].template.count
										}`}
									</td>
									<td>
										{`$${
											price.value * 0.3 > list[0].subsidies[0].rank.value3
												? (price.value * 0.3 - list[0].subsidies[0].rank.value3) / list[0].template.count
												: list[0].template.min / list[0].template.count
										}`}
									</td>
									<td>{`$${price.value * 0.7}`}</td>
								</tr>
							))}
						</tbody>
					</>
				) : (
					<>
						<thead>
							<tr>
								<th>Tipo</th>
								<th>Valor</th>
								<th>Reserva</th>
								<th>Cuotas</th>
								<th>Cuota mensual sugerida</th>
								<th>Crédito bancario</th>
							</tr>
						</thead>
						<tbody>
							{list[0].priceList?.map((price: any, index: number) => (
								<tr>
									<td>{price.type}</td>
									<td>{`$${price.value}`}</td>
									<td>{`$${price.reserveValue}`}</td>
									<td>{list[0].template.count}</td>
									<td>{`$${(price.value * 0.3) / list[0].template.count}`}</td>
									<td>{`$${price.value * 0.7}`}</td>
								</tr>
							))}
						</tbody>
					</>
				)}
			</table>
		</main>
	)
}
