export const tableHeader = [
   "ID",
   "Empresa",
   "Membresía",
   "Autorenovación",
   "Usuario",
   "Importe",
   "Estado",
   "Iva",
   "Fecha",
]
