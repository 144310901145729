import { FC } from "react"

import "./styles/index.css"

export const Modal: FC<any> = ({ children, onClose, open }) => {
	if (!open) return null

	return (
		<div className="modal">
			<div>
				<span onClick={onClose}>&times;</span>
				<div>{children}</div>
			</div>
		</div>
	)
}
