import { FC } from "react"

import "./styles/index.css"
import { IconAdd } from "../commons/Icons/IconAdd/IconAdd"

export const Add: FC<{ onClick?: any }> = ({ onClick }) => (
	<div className="plus" onClick={onClick}>
		<IconAdd />
	</div>
)
