import { useEffect } from "react"
import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import "../../styles/index.css"
import { validationSchema } from "./Register.validation"
import { register, registerReset } from "../../../../store/actions"
import { toastConfig } from "../../../../data"
import { AuthForm } from "../AuthForm/AuthForm"

export const Register = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const initialValues = { username: "", password: "" }

	const { data, error } = useSelector((state: any) => {
		return state.registerReducer
	})

	useEffect(() => {
		if (data.username)
			toast("🦄 Te registraste exitosamente!", {
				...toastConfig,
				onClose: () => navigate("/", { replace: true }),
			})
		else if (error === "UsernameAlreadyExists") toast("😰 El usuario ya existe", toastConfig)
		dispatch(registerReset())
		formik.resetForm()
	}, [data, error])

	const onSubmit = () => {
		const { username, password } = formik.values
		dispatch(register(username, password))
	}

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		onSubmit,
		validationSchema,
	})

	return (
		<>
			<AuthForm formik={formik} title="Registro" data={data} buttonText="Registrarse" link={{ link: "", text: "Ir a inicio de sesión" }} />
			<ToastContainer />
		</>
	)
}
