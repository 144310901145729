import { FC } from "react"

import { Input, Select } from ".."

export const Form: FC<any> = ({ formik }) => (
	<form onSubmit={formik.handleSubmit}>
		<div>
			<div>
				<Select name="selfRenewal" {...formik} label="Autorenovación" options={["Si", "No"]} />
			</div>
			<div>
				<Input {...formik} name="durationTime" label="Tiempo de duración" type="number" />
			</div>
		</div>
		<button type="submit">Enviar</button>
	</form>
)
