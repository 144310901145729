import styled from "styled-components"

export const TabsStyled = styled.div`
	display: flex;
	width: 100%;
	align-items: flex-start;
	padding: 0 1rem;
	flex-direction: column;
	> div.header {
		display: flex;
		flex-direction: row;
		margin-left: 9px;
		outline: 0;
		margin-top: -1px;
		> div {
			padding: 0.6rem 1.6rem;
			font-weight: 700;
			display: flex;
			font-size: 18px;
			text-transform: uppercase;
			border-right: 2px solid white;
			background-color: var(--secondary-color);
			color: white;
			&:first-child {
				border-bottom-left-radius: 8px;
			}
			&:last-child {
				border-right: 0;
				border-bottom-right-radius: 8px;
			}
			&.active {
				color: var(--secondary-color);
				background-color: var(--primary-color);
			}
		}
	}
`
