import { useEffect } from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import * as Yup from "yup"

import { TabsStyled } from "../Project/Tabs/Tabs.styled"
import { Container } from "./Container"
import "./CreateTemplate.styles.css"
import { required } from "../../utilities/errorMessages"
import { projectCreateTemplate, projectGet } from "../../store/actions"
import { RootState } from "../../store/store"

const validationSchema = Yup.object().shape({
	username: Yup.string().required(required),
	password: Yup.string().required(required),
})

const initialValues = {
	name: "",
	type: "",
}

export const CreateTemplate = () => {
	const dispatch = useDispatch()
	const { uuid } = useParams()
	const { list } = useSelector((state: RootState) => {
		return state.projectReducer
	})

	useEffect(() => dispatch(projectGet(`projects/readmodel/${uuid}`)), [list.state])

	const onSubmit = () => {
		dispatch(projectCreateTemplate("/projects/" + uuid + "/start-template", formik.values))
	}

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		onSubmit,
		validationSchema,
	})

	if (!list[0]?.template) return null

	return (
		<main id="create-template">
			<TabsStyled>
				<div className="header">
					<div className="active">Completar plantilla</div>
				</div>
			</TabsStyled>
			<DndProvider backend={HTML5Backend}>
				<Container template={list[0]?.template} uuid={list[0]?.id} />
			</DndProvider>
		</main>
	)
}
