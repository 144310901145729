import { useState, useEffect } from "react"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import "../admin.css"
import { tableHeader, search, title, titleModal } from "./data"
import { useFilterOptions, useSearchFilters, useForm } from "./userStories"
import { THead, TBody, Filters, Search, Aside, Add, Modal, Form, Empty } from "./components"
import { useStore, companiesReadModel } from "./store"

export const Companies = () => {
	const [openModal, setOpenModal] = useState(false)
	const [titleModalType, setTitleModalType] = useState<"edit" | "create" | "view" | "none">("none")
	const { dispatch, list, path, newGet } = useStore()
	const { renderList, handleFilteredList, handleSearch } = useSearchFilters(list, search.types)
	const { formik, edit, create, view, disabled } = useForm()
	const filterOptions = useFilterOptions()

	useEffect(() => dispatch(companiesReadModel(path)), [dispatch, newGet])

	useEffect(() => handleCloseModal(), [list])

	const handleEdit = (id: string) => {
		setOpenModal(true)
		setTitleModalType("edit")
		edit(id)
	}

	const handleView = (id: string) => {
		setOpenModal(true)
		setTitleModalType("view")
		view(id)
	}

	const handleAdd = () => {
		setOpenModal(true)
		setTitleModalType("create")
		create()
	}

	const handleCloseModal = () => setOpenModal(false)

	return (
		<>
			<main id="admin">
				<Aside />
				<section>
					<h1>{title}</h1>
					<div>
						<Search placeholder={search.placeholder} handleSearch={handleSearch} />
						<Filters handleFilteredList={handleFilteredList} options={filterOptions} />
					</div>
					{renderList.length ? (
						<table>
							<THead data={tableHeader} />
							<TBody list={renderList} edit={handleEdit} view={handleView} />
						</table>
					) : (
						<Empty text="Aun no hay empresas creadas..." />
					)}
				</section>
			</main>
			<Add onClick={handleAdd} />
			<Modal open={openModal} onClose={handleCloseModal}>
				{titleModalType !== "none" && <h1>{titleModal[titleModalType]}</h1>}
				<Form formik={formik} disabled={disabled} />
			</Modal>
			<ToastContainer />
		</>
	)
}
