import * as Yup from "yup"

import {
   required,
   minLength,
   email,
   youTube,
} from "../../../../../utilities/errorMessages"

export const ProjectTabsValidationSchema = Yup.object().shape({
   name: Yup.string().min(6, minLength(6)).required(required),
   description: Yup.string().required(required),
   email: Yup.string().email(email).required(required),
   video: Yup.string().matches(
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
      youTube
   ),
})
