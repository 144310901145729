import { useState, FC, ChangeEvent, useEffect } from "react"
import { memo } from "react"
import { arrayMoveImmutable } from "array-move"
import { useDispatch } from "react-redux"
import { v4 } from "uuid"

import { Box } from "./Box"
import { Dustbin } from "./Dustbin"
import { IconArrowSeparator, IconEdit } from "../../components/commons/Icons"
import { projectCreateTemplate } from "../../store/actions"

export const Container: FC<any> = memo(function Container({ template, uuid }) {
	const [tasks, setTasks] = useState<{ name: string; default: boolean; uuid: string }[]>([
		{ name: "Separación", default: true, uuid: v4() },
		{ name: "Toma de reservas", default: true, uuid: v4() },
		{ name: "Esquema de cuotas", default: true, uuid: v4() },
		{ name: "Cierre de saldos", default: true, uuid: v4() },
	])
	const [name, setName] = useState<string>("")
	const [editName, setEditName] = useState<boolean>(false)
	const dispatch = useDispatch()

	useEffect(() => {
		if (template) setName(template.name)
	}, [])

	const handleDrop = (item: string, uuid: string): void => setTasks((prev: { name: string; default: boolean; uuid: string }[]) => [...prev, { name: item, default: false, uuid }])

	const handleUndrop = (uuid: string): void => {
		const newTasks = tasks.filter((task: { uuid: string }) => task.uuid !== uuid)
		setTasks(newTasks)
	}

	const handleOnSortEnd = (oldIndex: number, newIndex: number) => {
		setTasks((array: any) => arrayMoveImmutable(array, oldIndex, newIndex))
	}

	const handleEditName = () => {
		dispatch(projectCreateTemplate(`projects/${uuid}/start-template`, { name, type: template.type }))
		setEditName(false)
	}

	const handleShowEdit = () => setEditName(true)

	return (
		<section>
			<div className="left" style={{ overflow: "hidden", clear: "both" }}>
				<h3>
					{!editName ? (
						<span>{name}</span>
					) : (
						<div className="change-name-wrapper">
							<input name="change-name-input" type="text" value={name} onChange={(event: ChangeEvent<HTMLInputElement>) => setName(event.target.value)} />
							<button type="button" className="change-name-button" onClick={handleEditName}>
								Editar
							</button>
						</div>
					)}
					<span onClick={handleShowEdit} title="Editar nombre">
						<IconEdit />
					</span>
				</h3>
				<Dustbin tasks={tasks} undropped={handleUndrop} onSortEnd={handleOnSortEnd} />
			</div>
			<div className="center">
				<IconArrowSeparator />
				<IconArrowSeparator />
				<IconArrowSeparator />
			</div>
			<div className="right" style={{ overflow: "hidden", clear: "both" }}>
				<h3>Pasos optativos</h3>
				<Box name="Solicitud de documentos virtual" dropped={handleDrop} />
				<Box name="Solicitud de documentos presencial" dropped={handleDrop} />
				<Box name="Firma de contrato" dropped={handleDrop} />
			</div>
		</section>
	)
})
