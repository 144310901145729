import { useSelector, useDispatch } from "react-redux"

import { RootState } from "../../../../store/store"

export const useStore = (): any => {
	const { list, newGet } = useSelector((state: RootState) => state.companiesReducer)
	const dispatch = useDispatch()
	const path = "companies/readmodel"

	return { list, newGet, dispatch, path }
}

export { companiesReadModel, companiesEditMembership } from "../../../../store/actions"
