import * as Yup from "yup"

const validations = {
	name: Yup.string().required("* Campo obligatorio").default(null).nullable(),
	type: Yup.string().required("* Campo obligatorio").default(null).nullable(),
	price: Yup.number().required("* Campo obligatorio").default(null).nullable(),
	priority: Yup.number().required("* Campo obligatorio").default(null).nullable(),
	durationTime: Yup.number().required("* Campo obligatorio").default(null).nullable(),
}

export const validationSchema = () => Yup.object().shape(validations)
