import { FC } from "react"

import { TElementList } from "../../entities"
import { IconEdit, IconEye } from "../../../../../components/commons/Icons"

export const TableBody: FC<any> = ({ list }) => {
   const bodyMap = () => {
      return list.map((row: TElementList) => {
         const {
            id,
            company,
            membership,
            auto_renovation,
            user,
            price,
            status,
            iva,
            date,
         } = row
         return (
            <tr key={id}>
               <td>{id}</td>
               <td>{company}</td>
               <td>{membership}</td>
               <td>{auto_renovation}</td>
               <td>{user}</td>
               <td>{price}</td>
               <td>{status}</td>
               <td>{iva}</td>
               <td>{date}</td>
            </tr>
         )
      })
   }

   return <tbody>{bodyMap()}</tbody>
}
