import { Dispatch } from "redux"

import { REGISTER_REQ, REGISTER_SUCCESS, REGISTER_FAIL, REGISTER_RESET } from "../types"

export const registerReq = () => ({ type: REGISTER_REQ })

export const registerSuccess = (data: { username: string; token: string }) => ({
	type: REGISTER_SUCCESS,
	payload: data,
})

export const registerFail = (error: any) => ({
	type: REGISTER_FAIL,
	payload: error,
})

export const registerReset = () => ({ type: REGISTER_RESET })

export const register = (username: string, password: string) => (dispatch: Dispatch) => {
	dispatch(registerReq())
	fetch(`http://localhost:1984/register`, {
		method: "POST",
		body: JSON.stringify({
			username,
			password,
		}),
		headers: { "Content-Type": "application/json" },
	})
		.then(response => response.json())
		.then(data => {
			if (data.type === "ConflictError") dispatch(registerFail(data.name))
			else dispatch(registerSuccess(data))
		})
		.catch(error => dispatch(registerFail(error)))
}
