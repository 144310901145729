import { COMPANIES_SUCCESS, COMPANIES_FAILURE, COMPANIES_REQUEST, COMPANY_SUCCESS } from "../types"

const initialState = {
	loading: false,
	list: [],
	error: "",
	newGet: false,
}

export const companiesReducer = <A>(
	state = initialState,
	action: {
		type: typeof COMPANIES_REQUEST | typeof COMPANIES_SUCCESS | typeof COMPANIES_FAILURE | typeof COMPANY_SUCCESS
		payload: A
	}
): any => {
	switch (action.type) {
		case COMPANIES_REQUEST: {
			return {
				...state,
				loading: true,
				newGet: false,
			}
		}
		case COMPANIES_SUCCESS: {
			return {
				loading: false,
				list: action.payload,
				error: "",
				newGet: false,
			}
		}
		case COMPANY_SUCCESS: {
			return {
				...state,
				loading: false,
				error: "",
				newGet: true,
			}
		}
		case COMPANIES_FAILURE: {
			return {
				loading: false,
				list: [],
				error: action.payload,
				newGet: false,
			}
		}
		default:
			return state
	}
}
