import { USERS_SUCCESS, USERS_FAILURE, USERS_REQUEST, USER_SUCCESS } from "../types"

const initialState = {
	loading: false,
	list: [],
	error: "",
	newGet: false,
}

export const usersReducer = <A>(
	state = initialState,
	action: {
		type: typeof USERS_REQUEST | typeof USERS_SUCCESS | typeof USERS_FAILURE | typeof USER_SUCCESS
		payload: A
	}
): any => {
	switch (action.type) {
		case USERS_REQUEST: {
			return {
				...state,
				loading: true,
				newGet: false,
			}
		}
		case USERS_SUCCESS: {
			return {
				loading: false,
				list: action.payload,
				error: "",
				newGet: false,
			}
		}
		case USER_SUCCESS: {
			return {
				loading: false,
				list: [],
				error: "",
				newGet: true,
			}
		}
		case USERS_FAILURE: {
			return {
				loading: false,
				list: [],
				error: action.payload,
				newGet: false,
			}
		}
		default:
			return state
	}
}
