import { useState, useEffect } from "react"
import { FormikValues, useFormik } from "formik"
import { v4 } from "uuid"
import { toast } from "react-toastify"

import { useStore, companiesPost, countriesReadModel } from "../store"
import { validationSchema } from "."
import { clearValues, toastConfig } from "../data"

export const useForm = () => {
	const [initialValues, setInitialValues] = useState<FormikValues>(clearValues)
	const [disabled, setDisabled] = useState<boolean>(false)
	const [createOrEdit, setCreateOrEdit] = useState<"create" | "edit" | "">("")
	const { list, dispatch, pathCountries } = useStore()

	useEffect(() => dispatch(countriesReadModel(pathCountries)), [])

	const edit = (id: string): void => {
		const company = list.find((company: any) => company.id === id)
		setInitialValues(company)
		setDisabled(false)
		setCreateOrEdit("edit")
	}

	const view = (id: string): void => {
		const company = list.find((company: any) => company.id === id)
		setInitialValues(company)
		setDisabled(true)
		setCreateOrEdit("")
	}

	const create = () => {
		formik.resetForm()
		setInitialValues(clearValues)
		setDisabled(false)
		setCreateOrEdit("create")
	}

	const onSubmit = (values: FormikValues) => {
		const { _id, id, lastEvent, memberships, name, lastName, userEmail, password, country, department, city, ...rest } = values
		const body = {
			...rest,
			user: {
				name,
				lastName,
				email: userEmail,
				password,
			},
			country: {
				name: country,
				department: {
					name: department,
					city: {
						name: city,
					},
				},
			},
		}
		dispatch(companiesPost(`companies/${values.id ? values.id : v4()}/${createOrEdit}-company`, body))
		setCreateOrEdit("")
		toast(`🦄 ${createOrEdit === "create" ? "Creaste exitosamente una" : "Editaste exitosamente la"} empresa`, toastConfig)
	}

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		validationSchema,
		onSubmit,
	})

	return { formik, edit, create, view, disabled }
}
