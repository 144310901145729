import { FC, useState, useEffect, ChangeEvent } from "react"
import { useParams } from "react-router-dom"
import { FormikValues, useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { v4 as uuid } from "uuid"
import { DeleteOutlined } from "@mui/icons-material"
import Swal from "sweetalert2"
import * as XLSX from "xlsx"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import "../../modal.styles.css"
import { FormStyled } from "../Form.styled"
import { validations } from "./userStories/validations"
import { RootState } from "../../../../store/store"
import { post, postReset, projectGet } from "../../../../store/actions"
import { toastConfig, swalConfig } from "../../../../data"
import { IconPeso } from "../../../../components/commons/Icons"
import { PricesListSubmit } from "./PricesList.types"

export const PricesList: FC<{ newId: (id: number) => void }> = ({ newId }) => {
	const [pricesList, setPricesList] = useState<string[] | []>([])
	const [name, setName] = useState<string>("")
	const [value, setValue] = useState<number>(1000)
	const [type, setType] = useState<string>("")
	const [reserveValue, setReserveValue] = useState<number>(0)
	const [errors, setErrors] = useState<any>()
	const [reservePercentage, setPercentageValue] = useState<number>(0)
	const { uuid: uuidByGet } = useParams()
	const dispatch = useDispatch()

	const { list } = useSelector((state: RootState) => {
		return state.projectReducer
	})

	const { result } = useSelector((state: RootState) => {
		return state.postReducer
	})

	useEffect(() => {
		if (uuidByGet) dispatch(projectGet(`projects/readmodel/${uuidByGet}`))
	}, [dispatch, uuidByGet])

	useEffect(() => {
		if (list[0]?.priceList) {
			setPricesList(list[0].priceList)
			formik.setFieldValue("pricesList", list[0].priceList)
		}
	}, [list])

	useEffect(() => {
		if (result) {
			toast("🦄 Actualizaste tu lista de precios!", toastConfig)
			dispatch(postReset())
		}
	}, [result])

	useEffect(() => {
		if (errors?.length) {
			Swal.fire({
				title: "😔 El archivo no se cargó por los siguientes errores",
				html: errors.join("<br/>"),
				confirmButtonText: "Aceptar",
				width: "700px",
			})
		}

		setErrors(null)
	}, [errors])

	const handleBack = () => newId(0)
	const handleForward = () => newId(2)

	const Prices = (): any => {
		if (pricesList?.length) {
			const totalValue = pricesList.map((item: any) => item.value).reduce((prev: number, next: number) => prev + next)
			const totalReserveValue = pricesList
				.map((item: any) => {
					if (item.reserveValue) return item.reserveValue
					else return (item.value / 100) * item.reservePercentage
				})
				.reduce((prev: number, next: number) => prev + next)
			return (
				<>
					<h2>Lista de precios</h2>
					<table>
						<thead>
							<tr>
								<th>Nombre</th>
								<th>Tipo</th>
								<th>Valor</th>
								<th>Reserva</th>
								<th>Porcentaje de reserva</th>
								<th>Eliminar</th>
							</tr>
						</thead>
						<tbody>
							{pricesList?.map((price: any) => (
								<tr key={price.uuid}>
									<td>{price.name}</td>
									<td>{price.type}</td>
									<td>$ {price.value}</td>
									<td>$ {price.reserveValue}</td>
									<td>
										<DeleteOutlined fontSize="small" className="after" style={{ fill: "#00263e" }} onClick={() => handleDeleteElementList(price.uuid)} />
									</td>
								</tr>
							))}
						</tbody>
						<tfoot>
							<tr>
								<td></td>
								<td></td>
								<td>$ {totalValue}</td>
								<td></td>
								<td>$ {totalReserveValue}</td>
								<td></td>
							</tr>
						</tfoot>
					</table>
				</>
			)
		}

		return <div className="without_results">Aun no se ha cargado ningún elemento</div>
	}

	const handleAddPrice = (): void => {
		if (!value || !name || (!reserveValue && !reservePercentage) || !type || (reserveValue === 0 && reservePercentage === 0)) {
			Swal.fire({
				title: "Debes completar todos los campos",
				text: "Por favor ingresa todos los valores",
				...swalConfig,
			})
			return
		} else {
			setPricesList((prev: any) => [
				{
					name,
					value: +value,
					type,
					reserveValue: +reserveValue,
					reservePercentage: +reservePercentage,
					uuid: uuid(),
				},
				...prev,
			])
		}

		setValue(1000)
		setName("")
		setReserveValue(0)
		setPercentageValue(0)
		setType("")

		formik.setFieldValue("pricesList", [{ name, value, type, reserveValue, reservePercentage }, ...pricesList])
	}

	const handleDeleteElementList = (uuid: string): void => {
		const newPricesList = pricesList.filter((price: any) => price.uuid !== uuid)
		setPricesList(newPricesList)
	}

	const excelToList = (file: any) => {
		setErrors(null)
		const promise = new Promise((resolve, reject) => {
			const fileReader = new FileReader()
			fileReader.readAsArrayBuffer(file)

			fileReader.onload = (e: any) => {
				const bufferArray = e.target.result
				const wb = XLSX.read(bufferArray, { type: "buffer" })
				const wsName = wb.SheetNames[0]
				const ws = wb.Sheets[wsName]
				const data = XLSX.utils.sheet_to_json(ws)
				resolve(data)
			}
			fileReader.onerror = (error: any) => reject(error)
		})
		promise.then((items: any) => {
			const getErrors = items.map((item: any, index: number) => {
				let errors: any = []
				if (!item.Nombre) errors.push("El Nombre en la fila " + (index + 2) + " no puede estar vacío.")
				else if (!item.Tipo) errors.push("El Tipo en la fila " + (index + 2) + " no puede estar vacío.")
				else if (!item.Valor) errors.push("El Valor en la fila " + (index + 2) + " no puede estar vacío.")
				else if ((!item["Reserva en pesos"] && !item["% de reserva"]) || (item["Reserva en pesos"] && item["% de reserva"]))
					errors.push("Se debe completar uno de los valores (Reserva en pesos o % de reserva) en la fila " + (index + 2))
				return errors
			})

			if (!getErrors?.flat().length) {
				const newElementsForList = items.map((item: any) => {
					return {
						name: item.Nombre,
						type: item.Tipo,
						value: item.Valor,
						reserveValue: item["Reserva en pesos"],
						uuid: uuid(),
					}
				})
				setPricesList((prev: any) => [...newElementsForList, ...prev])
			} else setErrors(getErrors.flat())
		})
	}

	const onSubmit = (values: FormikValues) => {
		const body = values.pricesList.map((val: PricesListSubmit) => {
			const { name, type, value, reserveValue, reservePercentage } = val
			if (reserveValue > 0) return { name, type, value, reserveValue }
			else return { name, type, value, reservePercentage: reservePercentage }
		})
		dispatch(post("projects/" + (uuidByGet ?? uuid()) + "/edit-price-list", body))
	}

	const initialValues = { pricesList: {} }

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		onSubmit,
		validationSchema: validations,
	})

	if (!list.length) return null

	return (
		<>
			<FormStyled>
				<form id="priceList" onSubmit={formik.handleSubmit}>
					<>
						<div className="right">
							<div className="sheets">
								<div>Descargar plantilla</div>
								<a href="/xls/plantilla_precios.xlsx" download>
									<img src="/img/sheet.png" alt="Bajar plantilla" />
								</a>
							</div>
							<div className="sheets" title="Formatos permitidos XSLX, XLS, CSV">
								<div>Cargar Excel</div>
								<input
									type="file"
									id="import-xlsx"
									onChange={(e: any) => {
										const file = e.target.files[0]
										e.target.value = ""
										excelToList(file)
									}}
									style={{ display: "none" }}
								/>
								<label htmlFor="import-xlsx">
									<img src="/img/sheet.png" alt="Subir listado" />
								</label>
							</div>
						</div>
						<div className="without_results mini">
							Para cada precio debes escoger entre completar el
							<b>VALOR DE RESERVA</b> o el <b>PORCENTAJE DE RESERVA</b>
							<br />
						</div>
						<div>
							<div>
								<label>Nombre *</label>
								<input name="name" value={name} onChange={(event: ChangeEvent<HTMLInputElement>) => setName(event.currentTarget.value)} />
							</div>
							<div>
								<label>Tipo *</label>
								<input name="type" value={type} onChange={(e: any) => setType(e.currentTarget.value)} />
							</div>
							<div style={{ position: "relative" }}>
								<IconPeso />
								<label>Valor *</label>
								<input name="value" type="number" min={1000} step={1000} value={value} onChange={(e: any) => setValue(e.currentTarget.value)} />
							</div>
							<div style={{ position: "relative" }}>
								<IconPeso />
								<label>Reserva en pesos</label>
								<input
									name="reserveValue"
									type="number"
									step={1000}
									min={0}
									value={reserveValue}
									onChange={(e: any) => {
										setReserveValue(+e.currentTarget.value)
										setPercentageValue(0)
									}}
								/>
							</div>
							<div style={{ flex: 0.5 }}>
								<button type="button" onClick={handleAddPrice}>
									Agregar
								</button>
							</div>
						</div>
						<input type="hidden" name="pricesList" />
						<Prices />
						<div className="button_wrapper">
							<button type="button" className="green" onClick={handleBack}>
								Atrás
							</button>
							<button type="submit">Guardar</button>
							<button type="button" onClick={handleForward}>
								Continuar
							</button>
						</div>
					</>
				</form>
			</FormStyled>
			<ToastContainer />
		</>
	)
}
