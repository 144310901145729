import { READ_MODEL_FAIL, READ_MODEL_REQ, READ_MODEL_SUCCESS } from "../types"

const initialState = {
   loading: false,
   list: [],
   error: "",
}

export const readModelProjectsReducer = <A>(
   state = initialState,
   action: {
      type:
         | typeof READ_MODEL_FAIL
         | typeof READ_MODEL_REQ
         | typeof READ_MODEL_SUCCESS
      payload: A
   }
): any => {
   switch (action.type) {
      case READ_MODEL_REQ: {
         return {
            ...state,
            loading: true,
         }
      }
      case READ_MODEL_SUCCESS: {
         return {
            loading: false,
            list: action.payload,
            error: "",
         }
      }
      case READ_MODEL_FAIL: {
         return {
            loading: false,
            list: [],
            error: action.payload,
         }
      }
      default:
         return state
   }
}
