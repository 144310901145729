import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import { RootState } from "../../store/store"
import { readModelProjects, post } from "../../store/actions"
import { ListRender } from "./List.render"

export const List = () => {
   const dispatch = useDispatch()
   const navigate = useNavigate()

   const { list } = useSelector((state: RootState) => {
      return state.readModelProjectsReducer
   })

   const { result } = useSelector((state: RootState) => {
      return state.postReducer
   })

   useEffect(() => {
      dispatch(readModelProjects("projects/readmodel"))
   }, [dispatch])

   useEffect(() => {
      if (result?.events?.[0].metadata?.name === "ProjectDeleted")
         dispatch(readModelProjects("projects/readmodel"))
   }, [result, dispatch])

   const handleSettings = (id: string) => navigate("/project/" + id)
   const handleLanding = (id: string) => navigate("/land/" + id)

   const handleRemove = (id: string) => {
      dispatch(post(`projects/${id}/delete-project`, {}))
   }

   return (
      <ListRender
         list={list}
         landing={handleLanding}
         settings={handleSettings}
         remove={handleRemove}
      />
   )
}
