import * as Yup from "yup"

const validations = {
	businessName: Yup.string().required("* Campo obligatorio").default(null).nullable(),
	yearExperience: Yup.number().required("* Campo obligatorio").default(null).nullable(),
	nit: Yup.number().required("* Campo obligatorio").default(null).nullable(),
	address: Yup.string().required("* Campo obligatorio").default(null).nullable(),
	department: Yup.string().required("* Campo obligatorio").default(null).nullable(),
	phone: Yup.string().required("* Campo obligatorio").default(null).nullable(),
	email: Yup.string().email("* Este campo debe ser un email").required("* Campo obligatorio").default(null).nullable(),
}

export const validationSchema = () => Yup.object().shape(validations)
