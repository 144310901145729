import { useState, useEffect } from "react"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import "../admin.css"
import { tableHeader, title, titleModal, emptyTable } from "./data"
import { crudTypeT } from "./entities"
import { useFilterOptions, useSearchFilters, useForm } from "./userStories"
import { THead, TBody, Filters, Aside, Add, Modal, Form, Empty } from "./components"
import { useStore, usersReadModel } from "./store"

export const UsersProfilesBackOffice = () => {
	const [openModal, setOpenModal] = useState(false)
	const [titleModalType, setTitleModalType] = useState<crudTypeT>("none")
	const { dispatch, list, path, newGet } = useStore()
	const { renderList, handleFilteredList } = useSearchFilters(list)
	const { formik, create } = useForm()
	const filterOptions = useFilterOptions()

	useEffect(() => dispatch(usersReadModel(path)), [dispatch, newGet])

	useEffect(() => disableModal(), [list])

	const enableModal = (type: crudTypeT, id?: string) => {
		setOpenModal(true)
		setTitleModalType(type)
		create()
	}

	const disableModal = () => setOpenModal(false)

	return (
		<>
			<main id="admin">
				<Aside />
				<section>
					<h1>{title}</h1>
					<div>
						<div />
						<Filters handleFilteredList={handleFilteredList} options={filterOptions} />
					</div>
					{renderList.length ? (
						<table>
							<THead data={tableHeader} />
							<TBody list={renderList} />
						</table>
					) : (
						<Empty text={emptyTable} />
					)}
				</section>
			</main>
			<Add onClick={() => enableModal("create")} />
			<Modal open={openModal} onClose={disableModal}>
				{titleModalType !== "none" && <h1>{titleModal[titleModalType]}</h1>}
				<Form formik={formik} />
			</Modal>
			<ToastContainer />
		</>
	)
}
