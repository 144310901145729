import { combineReducers } from "redux"

import { projectReducer } from "./projectReducer"
import { readModelProjectsReducer } from "./readModelProjectsReducer"
import { postReducer } from "./postReducer"
import { loginReducer } from "./loginReducer"
import { registerReducer } from "./registerReducer"
import { companiesReducer } from "./companies"
import { membershipsReducer } from "./memberships"
import { faqReducer } from "./faq"
import { usersReducer } from "./users"
import { servicesTypesReducer } from "./servicesTypes"
import { countriesReducer } from "./countries"

const rootReducers = combineReducers({
	projectReducer,
	postReducer,
	loginReducer,
	registerReducer,
	readModelProjectsReducer,
	companiesReducer,
	membershipsReducer,
	faqReducer,
	usersReducer,
	servicesTypesReducer,
	countriesReducer,
})

export default rootReducers
