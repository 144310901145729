import { useState } from "react"
import { FormikValues, useFormik } from "formik"
import { v4 } from "uuid"
import { toast } from "react-toastify"

import { useStore, userPost } from "../store"
import { crudTypeT } from "../entities"
import { validationSchema } from "."
import { clearValues, toastConfig } from "../data"

export const useForm = () => {
	const [initialValues, setInitialValues] = useState<FormikValues>(clearValues)
	const [crud, setCrud] = useState<crudTypeT>("none")
	const { companyList, dispatch } = useStore()

	const create = () => {
		formik.resetForm()
		setInitialValues(clearValues)
		setCrud("create")
	}

	const onSubmit = (values: FormikValues) => {
		const company = companyList.find((company: any) => company.businessName === values.company)
		dispatch(userPost(`users-profile/${v4()}/create-user-profile`, { ...values, company: company.id }))
		setCrud("none")
		toast(`🦄 Creaste exitosamente un usuario de compañia`, toastConfig)
	}

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		validationSchema,
		onSubmit,
	})

	return { formik, create }
}
