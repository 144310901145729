import { useState, useEffect } from "react"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import "../admin.css"
import { filterOptions, tableHeader, title, titleModal } from "./data"
import { useSearchFilters, useForm } from "./userStories"
import { THead, TBody, Filters, Aside, Add, Modal, Form } from "./components"
import { useStore, membershipsReadModel } from "./store"

export const MembershipSettings = () => {
	const [openModal, setOpenModal] = useState(false)
	const [titleModalType, setTitleModalType] = useState<"edit" | "create" | "none">("none")
	const { dispatch, list, path, newGet } = useStore()
	const { renderList, handleFilteredList } = useSearchFilters(list)
	const { formik, edit, create } = useForm()

	useEffect(() => dispatch(membershipsReadModel(path)), [dispatch, newGet, path])

	useEffect(() => handleCloseModal(), [list])

	const handleEdit = (id: string) => {
		setOpenModal(true)
		setTitleModalType("edit")
		edit(id)
	}

	const handleAdd = () => {
		setOpenModal(true)
		setTitleModalType("create")
		create()
	}

	const handleCloseModal = () => setOpenModal(false)

	return (
		<>
			<main id="admin">
				<Aside />
				<section>
					<h1>{title}</h1>
					<div>
						<div />
						<Filters handleFilteredList={handleFilteredList} options={filterOptions} />
					</div>
					<table>
						<THead data={tableHeader} />
						{renderList && <TBody list={renderList} edit={handleEdit} />}
					</table>
				</section>
			</main>
			<Add onClick={handleAdd} />
			<Modal open={openModal} onClose={handleCloseModal}>
				{titleModalType !== "none" && <h1>{titleModal[titleModalType]}</h1>}
				<Form formik={formik} />
			</Modal>
			<ToastContainer />
		</>
	)
}
