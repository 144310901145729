import { FC, useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { FormikValues, useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { v4 as uuid } from "uuid"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { FormStyled } from "../Form.styled"
import { TProjectTabProps } from "./ProjectTab.types"
import { ProjectTabsValidationSchema as validationSchema } from "./utilities/ProjectTab.validations"
import { RootState } from "../../../../store/store"
import { post, postReset, projectGet, projectReset } from "../../../../store/actions"
import { toastConfig } from "../../../../data"
import { ImagesUpload } from "../../../../components/commons/ImagesUpload"
import { IconBuilding } from "../../../../components/commons/Icons"
import { Input, Textarea, DateField, Switch } from "../../../../components/commons/Fields"

export const ProjectTab: FC<TProjectTabProps> = ({ newId }) => {
	const [initialValues, setInitialValues] = useState<FormikValues>({})
	const [newUuid, setNewUuid] = useState<string>("")
	const [files, setFiles] = useState<any>([])
	const { uuid: uuidByGet } = useParams()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const { list } = useSelector((state: RootState) => {
		return state.projectReducer
	})

	const { result } = useSelector((state: RootState) => {
		return state.postReducer
	})

	useEffect(() => {
		if (uuidByGet || newUuid) dispatch(projectGet(`projects/readmodel/${uuidByGet ? uuidByGet : newUuid}`))
	}, [dispatch, uuidByGet, newUuid])

	const createBody = () => {
		const { name, initialDate, email, description, video, subsideEnabled, endDate } = values

		const body = {
			name,
			email,
			description,
			status: 1,
			...(video ? { video } : {}),
			...(files.flat().map((file: any) => file.path).length ? { imageOrdering: files.flat().map((file: any) => file.path) } : { imageOrdering: [] }),
			...(subsideEnabled ? { subsideEnabled } : { subsideEnabled: false }),
			...(endDate ? { endDate } : {}),
			...(initialDate ? { initialDate } : {}),
		}

		return { body }
	}

	useEffect(() => {
		if (newUuid) {
			const { body } = createBody()
			dispatch(post("projects/" + newUuid + "/edit-project-info", body))
			navigate("/project/" + newUuid)
		}
	}, [newUuid])

	useEffect(() => {
		dispatch(projectReset())
	}, [])

	useEffect(() => {
		setInitialValues({
			name: list[0]?.name ?? "",
			email: list[0]?.email ?? "",
			description: list[0]?.description ?? "",
			video: list[0]?.video ?? "",
			status: 1,
			projectDateSwitch: false,
			subsideEnabled: list[0]?.subsideEnabled ?? false,
			initialDate: list[0]?.initialDate ? new Date(list[0].initialDate) : "",
			endDate: list[0]?.endDate ? new Date(list[0].endDate) : "",
		})
	}, [list])

	useEffect(() => {
		if (result) {
			toast(result?.state ? "🦄 Actualizaste tu proyecto" : "😰 Ocurrió un error", toastConfig)
			dispatch(postReset())
		}
	}, [result, dispatch])

	const handleBack = () => navigate("/list")
	const handleTemplate = () => navigate("/new-template/" + uuidByGet)
	const handleForward = () => newId(1)

	const onSubmit = () => {
		/*       if (files) {
         body = new FormData()
         body.append("images", files[0])
      } */
		if (!uuidByGet) setNewUuid(uuid())
		else {
			const { body } = createBody()
			/*          if (files)
            dispatch(
               postMultiPart(
                  "projects/" + uuidByGet + "/edit-project-info",
                  body
               )
            )
         else */
			dispatch(post(`projects/${uuidByGet}/edit-project-info`, body))
		}
	}

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		validationSchema,
		onSubmit,
	})

	const { handleSubmit, values } = formik

	return (
		<>
			<FormStyled>
				<form onSubmit={handleSubmit}>
					<div>
						<div>
							<IconBuilding />
							<Input label="Nombre del proyecto *" name="name" {...formik} />
						</div>
						<div id="switch">
							<Switch label="Habilitar subsidios" formik={formik} name="subsideEnabled" />
						</div>
					</div>
					<div>
						<Textarea label="Descripción *" name="description" {...formik} />
					</div>
					<div>
						<div>
							<Input label="Email del operador *" name="email" {...formik} />
						</div>
						<div>
							<Input label="Video" name="video" {...formik} />
						</div>
					</div>
					{!values.initialDate && (
						<div>
							<Switch label="Ver y/o establecer fecha de inicio y finalización del proyecto" formik={formik} name="projectDateSwitch" />
						</div>
					)}
					{(values.projectDateSwitch || values.initialDate) && (
						<div>
							<div>
								<DateField formik={formik} name="initialDate" label="Fecha de inicio del proyecto" />
							</div>
							<div>
								<DateField formik={formik} name="endDate" label="Fecha estimada de finalización del proyecto" />
							</div>
						</div>
					)}
					{!values.projectImagesSwitch ? (
						<div>
							<Switch label="Deseo subir imágenes" formik={formik} name="projectImagesSwitch" />
						</div>
					) : (
						<ImagesUpload files={files} setFiles={setFiles} />
					)}
					<div className="button_wrapper">
						{list[0]?.landing && (
							<button type="button" className="green" onClick={handleTemplate}>
								Crear plantilla
							</button>
						)}
						<button type="submit">Guardar</button>
						{uuidByGet && (
							<button type="button" onClick={handleForward}>
								Continuar
							</button>
						)}
					</div>
				</form>
			</FormStyled>
			<ToastContainer />
		</>
	)
}
