import { Dispatch } from "redux"

import { READ_MODEL_REQ, READ_MODEL_SUCCESS, READ_MODEL_FAIL } from "../types"
import { fetchActions } from "../fetch"

export const readModelRequest = (): { type: typeof READ_MODEL_REQ } => ({
	type: READ_MODEL_REQ,
})

export const readModelSuccess = <A>(data: A): { type: typeof READ_MODEL_SUCCESS; payload: A } => ({
	type: READ_MODEL_SUCCESS,
	payload: data,
})

export const readModelFailure = (error: string): { type: typeof READ_MODEL_FAIL; payload: string } => ({
	type: READ_MODEL_FAIL,
	payload: error,
})

export const readModelProjects =
	(path: string): any =>
	(dispatch: Dispatch) => {
		dispatch(readModelRequest())
		fetchActions({
			dispatch,
			port: process.env.REACT_APP_PORT_BACKEND as string,
			path,
			success: readModelSuccess,
			failure: readModelFailure,
			method: "get",
		})
	}
