import styled from "styled-components"

export const FormStyled = styled.div`
	display: flex;
	width: 100%;
	align-items: flex-start;
	padding: 1rem 1.5rem 0.75rem;
	flex-direction: column;
	/* FORM */
	form {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin-top: 10px;
		> #lista_subsidios {
			display: flex;
			flex-direction: column;
			width: 100%;
			> div {
				position: relative;
				justify-content: flex-start;
				display: flex;
				width: 50%;
				> button {
					border: 2px solid var(--primary-color);
					border-radius: 8px;
					padding: 3px 15px;
					font-size: 14px;
					color: white;
					background-color: var(--primary-color);
					cursor: pointer;
					transition: all 0.3s ease-out;
					min-height: 40px;
					height: 40px;
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
					margin-top: 0;
				}
				> input {
					height: 40px;
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
					padding-left: 40px;
				}
				> .after {
					right: 95px;
					top: 10px;
				}
			}
			> h2 {
				margin-bottom: 0;
				font-size: 1.3rem;
			}
			> ul {
				padding-left: 0;
				margin: 0.8rem 0;
				border-radius: 0;
				border: 1px solid var(--primary-color);
				> li {
					list-style: none;
					font-weight: 400;
					font-size: 0.875rem;
					line-height: 1.2;
					letter-spacing: 0.01071em;
					vertical-align: inherit;
					text-align: left;
					padding: 12px;
					background-color: #fafafa;
					display: flex;
					justify-content: space-between;
					position: relative;
					&:nth-of-type(even) {
						background-color: #e8f5ff;
					}
					> .after {
						top: 11px;
					}
				}
			}
		}
		> #rangos {
			display: flex;
			flex-direction: row;
			width: 100%;
			> div {
				flex: 1;
				flex-direction: column;
				padding: 5px 10px 15px 0;
				&:nth-child(even) {
					padding: 5px 0px 15px 10px;
				}
				> div {
					border: 1px solid var(--primary-color);
					background-color: #fafafa;
					border-radius: 8px;
					padding: 0 20px 10px;
					position: relative;

					> h2 {
						margin-bottom: 10px;
						font-size: 1.3rem;
					}
					> label {
						font-size: 14px;
						padding-bottom: 10px;
						display: block;
						font-family: "Sansation Bold", sans-serif;
					}
					> input {
						border: 1px solid var(--primary-color);
						border-radius: 8px;
						width: 100%;
						outline: 0;
						padding: 13px 15px;
						font-size: 16px;
						font-weight: 300;
						color: black;
						position: relative;
						height: 40px;
						margin-bottom: 19px;
					}
				}
				&.withOutRange {
					flex-direction: row;
					display: flex;
					background-color: #fafafa;
					border: 1px solid var(--primary-color);
					border-radius: 8px;
					padding: 0 10px;
					justify-content: space-between;
					> div {
						background: transparent;
						border: 0;
						flex: 3;
						padding: 15px 10px 0;
						&.buttonWrapper {
							flex: 1;
						}
						> label {
							padding-bottom: 4px;
							font-size: 14px;
							color: rgb(51, 51, 51);
							display: block;
							font-family: "Sansation Bold", sans-serif;
						}
						> button {
							width: 100%;
							margin-top: 19px;
							height: 39px;
						}
						> .before {
							top: 45px;
							left: 21px;
						}
						> input {
							padding-right: 15px;
							padding-left: 35px;
						}
					}
				}
			}
			label.cargarRangos {
				width: 100%;
				display: flex;
			}
		}
		> div {
			position: relative;
			display: flex;
			justify-content: space-between;
			margin-bottom: 8px;
			flex-wrap: wrap;
			gap: 0 12px;
			&._50 {
				width: 50%;
				> input {
					padding-left: 2.3rem !important;
				}
			}
			> input,
			> textarea,
			> select {
				border: 1px solid var(--primary-color);
				border-radius: 8px;
				outline: 0;
				padding: 13px 13px;
				font-size: 16px;
				font-weight: 300;
				color: black;
				position: relative;
				height: 38px;
				margin-bottom: 0.3rem;
				width: 100%;
				&:hover {
					background-color: #fafafa;
				}
				&.error {
					border-color: rgb(255, 69, 43);
				}
			}
			input[name="name"],
			input[name="value"],
			input[name="reserveValue"] {
				padding-left: 40px !important;
			}
			> .errorMessage {
				color: rgb(255, 69, 43);
				font-size: 14px;
				padding-top: 2px;
			}
			> textarea {
				height: auto;
				min-height: 120px;
			}
			> label {
				padding-bottom: 7px;
				font-size: 14px;
				color: rgb(51, 51, 51);
				display: block;
				font-family: "Sansation Bold", sans-serif;
			}
			> .before {
				position: absolute;
				left: 12px;
				top: 37px;
				z-index: 1;
			}
			> div {
				flex: 1;
				width: 100%;
				> input,
				> .react-datepicker-wrapper input {
					border: 1px solid var(--primary-color);
					border-radius: 8px;
					outline: 0;
					padding: 13px 13px;
					font-size: 16px;
					font-weight: 300;
					color: black;
					position: relative;
					height: 38px;
					margin-bottom: 0.2rem;
					width: 100%;
					&:hover {
						background-color: #fafafa;
					}
					&.error {
						border-color: rgb(255, 69, 43);
					}
				}
				.react-datepicker__day--keyboard-selected {
					background-color: var(--primary-color);
				}
				.react-datepicker {
					border: 0;
					.react-datepicker__header {
						color: white;
						background-color: var(--primary-color);
						border: 0;
						border-radius: 0;
						.react-datepicker__current-month {
							color: white;
						}
					}
					.react-datepicker__day--selected,
					.react-datepicker__day--selected:hover {
						background-color: var(--primary-color);
					}
					.react-datepicker__month-container {
						background-color: #fafafa;
					}
					.react-datepicker__triangle {
						display: none;
					}
				}
				> label {
					padding-bottom: 7px;
					font-size: 14px;
					color: rgb(51, 51, 51);
					display: block;
					font-family: "Sansation Bold", sans-serif;
				}
				> button {
					padding: 8px 18px;
					margin-top: 21.5px;
					height: 40px;
				}
				> .imageUploader {
					> .areaDragging {
						display: flex;
						padding: 40px;
						border: 0.5px solid #e9e9e9;
						width: 100%;
						justify-content: center;
						background-color: #fafafa;
						border-radius: 8px;
						cursor: pointer;
						margin-bottom: 15px;
						&.dragging {
							border: 1px dashed var(--primary-color);
							font-size: 22px;
							color: var(--primary-color);
							background-color: white;
						}
					}
					> .image-items {
						display: flex;
						padding: 3px;
						border: 0.5px solid #e9e9e9;
						width: 100%;
						background-color: #fafafa;
						border-radius: 8px;
						cursor: pointer;
						margin-top: 5px;
						flex-wrap: wrap;
						justify-content: flex-grow;
						> .image-item {
							display: inline-flex;
							padding: 10px;
							position: relative;
							> img {
								border-radius: 8px;
							}
							> .buttons {
								background-color: rgb(51, 51, 51);
								height: 30px;
								padding: 5px 3px;
								width: 48px;
								position: absolute;
								right: 20px;
								top: 20px;
								border-radius: 6px;
								display: flex;
								flex-direction: row;
							}
						}
						label {
							padding-bottom: 7px;
							font-size: 14px;
							color: rgb(51, 51, 51);
							display: block;
							font-family: "Sansation Bold", sans-serif;
						}
					}
				}
			}
		}
		> .button_wrapper {
			width: 100%;
			margin: 10px 0 5px;
			justify-content: start;
			gap: 0 8px;
			> button {
				transition: all 0.3s ease;
				box-sizing: border-box;
				font-size: 18px;
				min-height: 33px;
				padding: 10px 20px;
				&:hover,
				&:focus {
					transform: scale(1.02);
				}
				&.green {
					background-color: var(--secondary-color) !important;
					border-color: var(--secondary-color) !important;
				}
			}
			> button:first-child {
				background-color: #3483f7;
				border-color: #3483f7;
			}
		}
		&#auth {
			width: 350px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: white;
			border-radius: 8px;
			padding: 10px 20px 15px;
			> h1 {
				font-size: 24px;
				margin-top: 10px;
			}
			.button_wrapper {
				> button {
					width: 100%;
					height: 46px;
					&:hover,
					&:focus {
						transform: scale(1);
					}
				}
			}
			a {
				text-decoration: none;
				color: black;
				font-size: 13px;
				margin-top: 4px;
			}
		}
	}

	/* FINAL PARTS OF FORM */
	form {
		position: relative;
		> input,
		> textarea,
		> select {
			border: 0.5px solid #e9e9e9;
			border-radius: 8px;
			outline: 0;
			padding: 13px 13px;
			font-size: 16px;
			font-weight: 300;
			color: black;
			position: relative;
			height: 38px;
			margin-bottom: 0.3rem;
			width: 100%;
			&:hover {
				background-color: #fafafa;
			}
		}
		#switch {
			display: flex;
			justify-content: flex-end;
			border-radius: 8px;
			position: absolute;
			top: -44px;
			> label {
				margin-right: 0;
				> span:nth-child(2) {
					font-size: 14px;
					font-family: "Nunito", sans-serif;
				}
			}
		}
		.before {
			position: absolute;
			left: 12px;
			top: 31px;
			z-index: 1;
		}
		.after {
			position: absolute;
			right: 15px;
			top: 34px;
			cursor: pointer;
		}
		select#choose {
			padding: 3px 6px !important;
		}
		.errorMessage {
			color: rgb(255, 69, 43);
			font-size: 14px;
			padding-top: 2px;
		}
	}
	.before.beforetitle {
		top: 10px !important;
	}

	.sheets {
		display: flex;
		flex-direction: row;
		gap: 0 8px;
		flex: auto;
		width: auto;
		margin-left: 8px;
		margin-top: -36px;
		div {
			font-size: 14px;
			color: rgb(51, 51, 51);
			font-family: "Sansation Bold", sans-serif;
		}
		img {
			width: 24px;
			position: relative;
			top: -3px;
			height: 24px;
			cursor: pointer;
		}
	}
	.right {
		margin-left: auto;
	}
	.without_results {
		background-color: #e8f5ff;
		border: 1px solid var(--primary-color);
		padding: 40px;
		margin: 15px 0;
		font-weight: 400;
		justify-content: center;
		font-size: 18px;
		border-radius: 8px;
	}

	.without_results.mini {
		padding: 15px;
		font-size: 15px;
	}
	.lint {
		background-color: #ffe900;
	}
	svg {
		cursor: pointer;
	}
	.center {
		justify-content: center !important;
	}

	table {
		margin: 10px 0 15px;
		border-collapse: collapse;
	}
	tr {
		background-color: #fafafa;
		border-bottom: 2px solid white;
		&:nth-of-type(even) {
			background-color: #ffe8e5;
			background-color: white;
		}
	}
	thead tr {
		background-color: var(--primary-color);
	}
	tfoot tr {
		background-color: #e8f5ff;
	}
	td,
	th {
		text-align: center;
		padding: 8px;
		font-size: 15px;
		color: rgb(51, 51, 51);
		font-weight: 400;
		position: relative;
	}
	td .after {
		top: 7px;
		right: 35px;
	}

	td.red {
		color: var(--primary-color);
		font-weight: bold;
	}
	th {
		color: white;
	}
	tfoot td {
		font-weight: 700;
	}
	#priceList input[name="name"] {
		padding-left: 13px !important;
	}
`
