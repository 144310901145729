export const filterOptions = [
	{
		tag: "type",
		es: "paquete",
		options: [
			{ label: "Business", value: "business" },
			{ label: "Conet", value: "conet" },
		],
	},
	{
		tag: "membership",
		es: "membresía",
		options: [
			{ label: "Oro", value: "oro" },
			{ label: "Plata", value: "plata" },
			{ label: "Bronce", value: "bronce" },
		],
	},
]
