import { TElementList } from "../../entities"

export const TableBody = ({ list }: { list: TElementList[] }) => (
	<tbody>
		{list?.map(({ id, company, username, rol, email }: TElementList) => {
			if (!company)
				return (
					<tr key={id}>
						{[id, company, username, rol, email].map((item: string, key: number) => (
							<td key={key}>{item}</td>
						))}
					</tr>
				)
		})}
	</tbody>
)
