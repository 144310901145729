import { useState } from "react"
import { FormikValues, useFormik } from "formik"
import { v4 } from "uuid"
import { toast } from "react-toastify"

import { useStore, companiesEditMembership } from "../store"
import { validationSchema } from "."
import { clearValues, toastConfig } from "../data"

export const useForm = () => {
	const [initialValues, setInitialValues] = useState<FormikValues>(clearValues)
	const [createOrEdit, setCreateOrEdit] = useState<"create" | "edit" | "">("")
	const { list, dispatch } = useStore()

	const edit = (companyId: string, membershipId: string): void => {
		const company = list.find((company: any) => company.id === companyId)
		const membership = company.memberships.find((membership: any) => membership.uuid === membershipId)
		setInitialValues({ companyId: company.id, uuid: membership.uuid, durationTime: membership.durationTime, selfRenewal: membership.selfRenewal ? "Si" : "No", type: membership.type })
		setCreateOrEdit("edit")
	}

	const create = () => {
		formik.resetForm()
		setInitialValues(clearValues)
		setCreateOrEdit("create")
	}

	const onSubmit = (values: FormikValues) => {
		const { companyId, selfRenewal, durationTime, ...rest } = values
		dispatch(
			companiesEditMembership(`companies/${values.companyId}/edit-memberships`, [
				{
					...rest,
					selfRenewal: selfRenewal === "Si",
					application: "modificado",
					expirationDate: "2022-06-11T05:29:39.419Z",
				},
			])
		)
		setCreateOrEdit("")
		toast(`🦄 ${createOrEdit === "create" ? "Creaste exitosamente una" : "Editaste exitosamente la"} empresa`, toastConfig)
	}

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		validationSchema,
		onSubmit,
	})

	return { formik, edit, create }
}
