import { FC } from "react"

import { Input, Textarea } from ".."

export const Form: FC<any> = ({ formik }) => (
	<form onSubmit={formik.handleSubmit}>
		<div>
			<div>
				<Input {...formik} name="type" label="Tipo" />
			</div>
		</div>
		<div>
			<div>
				<Textarea {...formik} name="description" label="Descripción" />
			</div>
		</div>
		<button type="submit">Enviar</button>
	</form>
)
