import { FC } from "react"

import { Input, Textarea } from ".."

export const Form: FC<any> = ({ formik }) => (
	<form onSubmit={formik.handleSubmit}>
		<div>
			<div>
				<Input {...formik} name="username" label="Nombre" />
			</div>
		</div>
		<div>
			<div>
				<Input {...formik} name="email" typw="email" label="Email" />
			</div>
		</div>
		<div>
			<div>
				<Input {...formik} name="rol" label="rol" />
			</div>
		</div>
		<button type="submit">Enviar</button>
	</form>
)
