import { useSelector, useDispatch } from "react-redux"

import { RootState } from "../../../../../store/store"

export const useStore = (): any => {
	const { list, newGet } = useSelector((state: RootState) => state.servicesTypesReducer)
	const dispatch = useDispatch()
	const path = "service_types/readmodel"

	return { list, dispatch, path, newGet }
}

export { servicesTypesReadModel, serviceTypePost } from "../../../../../store/actions"
