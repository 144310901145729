import * as Yup from "yup"

const validations = {
	username: Yup.string().required("* Campo obligatorio").default(null).nullable(),
	company: Yup.string().required("* Campo obligatorio").default(null).nullable(),
	email: Yup.string().email("Debes escribir un email").required("* Campo obligatorio").default(null).nullable(),
	rol: Yup.string().required("* Campo obligatorio").default(null).nullable(),
}

export const validationSchema = () => Yup.object().shape(validations)
