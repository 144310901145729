import { useSelector, useDispatch } from "react-redux"

import { RootState } from "../../../../../store/store"

export const useStore = (): any => {
	const { list, newGet } = useSelector((state: RootState) => state.faqReducer)
	const dispatch = useDispatch()
	const path = "frecuentquestions/readmodel"

	return { list, dispatch, path, newGet }
}

export { faqsReadModel, faqPost } from "../../../../../store/actions"
