import { FC } from "react"
import { TInputFieldProps } from "./Fields.types"

export const InputMini: FC<TInputFieldProps> = ({
   errors,
   touched,
   handleBlur,
   handleChange,
   label,
   name,
   values,
   type = "text",
}) => (
   <>
      <label style={{ width: 200 }}>{label}</label>
      <input
         onBlur={handleBlur}
         onChange={handleChange}
         type={type}
         name={name}
         className={errors[name] && touched[name] && "error"}
         value={values[name]}
         style={{ width: 100, position: "relative", top: -8 }}
      />
      {errors[name] && touched[name] && (
         <div className="errorMessage">{errors[name]}</div>
      )}
   </>
)
