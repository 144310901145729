export const filterOptions = [
	{
		tag: "membership",
		es: "membresía",
		options: [
			{ label: "Gold", value: "gold" },
			{ label: "Silver", value: "silver" },
			{ label: "Free", value: "free" },
		],
	},
]
