import { FC } from "react"
import { useDrag } from "react-dnd"
import { v4 } from "uuid"

import { ItemTypes } from "./ItemTypes"

export interface BoxProps {
	name: string
	dropped: (item: string, uuid: string) => void
}

interface DropResult {
	name: string
}

export const Box: FC<BoxProps> = function Box({ name, dropped }) {
	const [{ isDragging }, drag] = useDrag(() => ({
		type: ItemTypes.BOX,
		item: { name },
		end: (item, monitor) => {
			const dropResult = monitor.getDropResult<DropResult>()
			if (item && dropResult) dropped(name, v4())
		},
		collect: monitor => ({
			isDragging: monitor.isDragging(),
			handlerId: monitor.getHandlerId(),
		}),
	}))

	const opacity = isDragging ? 0.4 : 1

	return (
		<div ref={drag} style={{ opacity }} data-testid={`box`}>
			{name}
		</div>
	)
}
