import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import "../../styles/index.css"
import { validationSchema } from "./utilities/Login.validations"
import { login, loginReset } from "../../../../store/actions"
import { toastConfig } from "../../../../data"
import { AuthForm } from "../AuthForm/AuthForm"

export const Login = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const initialValues = { username: "", password: "" }

	const { data, loading, error } = useSelector((state: any) => {
		return state.loginReducer
	})

	useEffect(() => {
		if (data.token) {
			localStorage.setItem("token", data.token)
			localStorage.setItem("username", data.username)
			navigate("/list")
		} else if (data.type === "ForbiddenError") {
			dispatch(loginReset())
			toast("😰 Las credenciales no son válidas", toastConfig)
			formik.resetForm()
		}
	}, [data, navigate, dispatch])

	const onSubmit = () => {
		const { username, password } = formik.values
		dispatch(login(username, password))
	}

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		onSubmit,
		validationSchema,
	})

	return (
		<>
			<header className="auth">
				<img src="/img/conet_logo.svg" alt="Logo Conet" />
			</header>
			<AuthForm formik={formik} title="Iniciar Sesión" data={data} buttonText="Ingresar" link={{ link: "register", text: "Ir al registro" }} />
			<ToastContainer />
		</>
	)
}
