import { useState, FC, useEffect, ChangeEvent, ReactElement } from "react"
import { FormikValues, useFormik } from "formik"
import { useNavigate, useParams } from "react-router-dom"
import { DeleteOutlined } from "@mui/icons-material"
import { v4 as uuid } from "uuid"
import { useDispatch, useSelector } from "react-redux"
import Swal from "sweetalert2"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import "../../modal.styles.css"
import { FormStyled } from "../Form.styled"
import { post, projectGet } from "../../../../store/actions"
import { RootState } from "../../../../store/store"
import { toastConfig, swalConfig } from "../../../../data"
import { IconDelete, IconTitle, IconPeso } from "../../../../components/commons/Icons"

export const Subsidies: FC<{ newId: (id: number) => void }> = ({ newId }) => {
	const [newSubsidy, setNewSubsidy] = useState<string>("")
	const [listSubsidies, setListSubsidies] = useState<string[]>([])
	const [declaredRange, setDeclaredRange] = useState<boolean>(false)
	const [min, setMinRange] = useState<number>(0)
	const [max, setMaxRange] = useState<number>(0)
	const [value1, setValue1] = useState<number>(0)
	const [value2, setValue2] = useState<number>(0)
	const [value3, setValue3] = useState<number>(0)
	const [subsidyAlreadyExists, setSubsidyAlreadyExists] = useState<boolean>(false)
	const navigate = useNavigate()
	const [initialValues] = useState<FormikValues>({})
	const { uuid: uuidByGet } = useParams()
	const dispatch = useDispatch()

	const { list } = useSelector((state: RootState) => {
		return state.projectReducer
	})

	useEffect(() => {
		if (uuidByGet) dispatch(projectGet(`projects/readmodel/${uuidByGet}`))
	}, [dispatch, uuidByGet])

	useEffect(() => {
		if (list[0].subsidies?.length) {
			setListSubsidies(list[0].subsidies.map((subsidy: { name: string }) => subsidy.name))
			setMaxRange(list[0].subsidies[0].rank.max)
			setMinRange(list[0].subsidies[0].rank.min)
			setValue1(list[0].subsidies[0].rank.value1)
			setValue2(list[0].subsidies[0].rank.value2)
			setValue3(list[0].subsidies[0].rank.value3)
			setDeclaredRange(true)
		}
	}, [list[0]])

	const handleBack = () => newId(1)

	const handleDeleteElementList = (name: string) => setListSubsidies(listSubsidies.filter(subsidy => subsidy !== name))

	const Subsidios = (): ReactElement => {
		if (listSubsidies?.length)
			return (
				<>
					<h2>Subsidios cargados</h2>
					<ul>
						{listSubsidies?.map((subsidio: string, index: number) => (
							<li key={index}>
								{subsidio}
								<DeleteOutlined fontSize="small" className="after" style={{ fill: "#00263e" }} onClick={() => handleDeleteElementList(subsidio)} />
							</li>
						))}
					</ul>
				</>
			)
		return (
			<div className="without_results" style={{ width: "100%" }}>
				Aun no se han cargado subsidios
			</div>
		)
	}

	const handleAddSubsidy = (): void => {
		if (newSubsidy !== "") {
			if (listSubsidies.indexOf(newSubsidy) === -1) {
				setListSubsidies((prev: string[] | []) => [newSubsidy, ...prev])
				setNewSubsidy("")
			} else setSubsidyAlreadyExists(true)
		}
	}

	const handleCrealNewSubsidy = () => setNewSubsidy("")

	const handleAddRange = () => {
		if (min < 1 || max < 10000 || min >= max) {
			Swal.fire({
				title: "Debes completar correctamente los campos",
				text: "El mínimo es 1, el máximo debe ser superior a 10000 y el máximo debe ser superior al mínimo",
				...swalConfig,
			})
			return
		} else setDeclaredRange(true)
	}

	const onSubmit = () => {
		if (!declaredRange) {
			Swal.fire({
				title: "Debes declarar el rango salarial",
				text: "El mínimo es 1, el máximo debe ser superior a 10000 y el máximo debe ser superior al mínimo",
				...swalConfig,
			})
			return
		} else if (!listSubsidies.length) {
			Swal.fire({
				title: "Subsidios requeridos",
				text: "Debes agregar al menos un nombre de subsidio",
				...swalConfig,
			})
			return
		} else if (!value1 || !value2 || !value3) {
			Swal.fire({
				title: "Los 3 montos son requeidos",
				text: "Debes agregar los 3 montos",
				...swalConfig,
			})
			return
		}

		const body = listSubsidies?.map((subsidy: string) => ({
			name: subsidy,
			rank: { min, max, value1, value2, value3 },
		}))

		dispatch(post("projects/" + (uuidByGet ?? uuid()) + "/edit-grant-list", body))
		toast("🦄 Actualizaste tus subsidios", toastConfig)
	}

	const handleForward = () => newId(3)

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		onSubmit,
	})

	return (
		<FormStyled>
			<form onSubmit={formik.handleSubmit}>
				<div id="lista_subsidios">
					<div>
						<IconTitle />
						<input
							value={newSubsidy}
							onChange={(event: ChangeEvent<HTMLInputElement>) => {
								setNewSubsidy(event.currentTarget.value)
								setSubsidyAlreadyExists(false)
							}}
							placeholder="Nuevo subsidio"
						/>
						{!!newSubsidy && <IconDelete clear={handleCrealNewSubsidy} name="title" />}
						<button type="button" id="agregarsubsidio" onClick={handleAddSubsidy}>
							Agregar
						</button>
					</div>
					{subsidyAlreadyExists && <div>Este subsidio ya fue creado!</div>}
					<Subsidios />
				</div>
				<div id="rangos">
					{declaredRange ? (
						<>
							<div>
								<div>
									<h2>Importe de salarios</h2>
									<label>Rango mínimo</label>
									<input type="text" disabled value={`Desde 1 hasta ${min - 1} pesos`} />
									<label>Rango medio</label>
									<input type="text" disabled value={`Entre ${min} y ${max - 1} pesos`} />
									<label>Rango máximo</label>
									<input type="text" disabled value={`Desde ${max} pesos`} />
								</div>
							</div>
							<div>
								<div>
									<h2>Valor de subsidio</h2>
									<label>Monto mínimo</label>
									<input type="number" step={1000} placeholder="Monto mínimo" value={value1} onChange={(event: ChangeEvent<HTMLInputElement>) => setValue1(+event.currentTarget.value)} />
									<label>Monto medio</label>
									<input type="number" step={1000} placeholder="Monto medio" value={value2} onChange={(event: ChangeEvent<HTMLInputElement>) => setValue2(+event.currentTarget.value)} />
									<label>Monto máximo</label>
									<input type="number" step={1000} placeholder="Monto máximo" value={value3} onChange={(event: ChangeEvent<HTMLInputElement>) => setValue3(+event.currentTarget.value)} />
								</div>
							</div>
						</>
					) : (
						<>
							<label className="cargarRangos">Cargar rango salarial</label>
							<div className="withOutRange">
								<div>
									<label>Mínimo</label>
									<input type="number" value={min} step={1000} onChange={(event: ChangeEvent<HTMLInputElement>) => setMinRange(+event.currentTarget.value)} />
									<IconPeso />
								</div>
								<div>
									<label>Máximo</label>
									<input type="number" value={max} step={1000} onChange={(event: ChangeEvent<HTMLInputElement>) => setMaxRange(+event.currentTarget.value)} />
									<IconPeso />
								</div>
								<div className="buttonWrapper">
									<button type="button" onClick={handleAddRange}>
										Agregar
									</button>
								</div>
							</div>
						</>
					)}
				</div>
				<div className="button_wrapper">
					<button className="green" type="button" onClick={handleBack}>
						Volver
					</button>
					<button type="submit">Guardar</button>
					<button type="button" onClick={handleForward}>
						Continuar
					</button>
				</div>
			</form>
			<ToastContainer />
		</FormStyled>
	)
}
