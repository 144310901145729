import { Dispatch } from "redux"

import { USERS_SUCCESS, USERS_FAILURE, USERS_REQUEST, USER_SUCCESS } from "../types"
import { fetchActions } from "../fetch"

export const usersRequest = (): { type: typeof USERS_REQUEST } => ({
	type: USERS_REQUEST,
})

export const usersSuccess = <A>(data: A): { type: typeof USERS_SUCCESS; payload: A } => {
	return {
		type: USERS_SUCCESS,
		payload: data,
	}
}

export const userSuccess = () => {
	return {
		type: USER_SUCCESS,
	}
}

export const usersFailure = (error: string): { type: typeof USERS_FAILURE; payload: string } => ({
	type: USERS_FAILURE,
	payload: error,
})

export const usersReadModel =
	(path: string): any =>
	(dispatch: Dispatch) => {
		dispatch(usersRequest())
		fetchActions({
			dispatch,
			port: process.env.REACT_APP_PORT_BACKOFFICE as string,
			path,
			success: usersSuccess,
			failure: usersFailure,
			method: "get",
		})
	}

export const userPost =
	(path: string, body: any): any =>
	(dispatch: Dispatch) => {
		dispatch(usersRequest())
		fetchActions({
			dispatch,
			port: process.env.REACT_APP_PORT_BACKOFFICE as string,
			path,
			success: userSuccess,
			failure: usersFailure,
			method: "post",
			body,
		})
	}
