import { FC } from "react"

import type { AuthPropsT } from "../../entities"
import { Input } from "../../../../components/commons/Fields"

export const AuthForm: FC<AuthPropsT> = ({ formik, title, buttonText, link }) => (
	<div id="auth-page">
		<main>
			<form id="auth" onSubmit={formik.handleSubmit}>
				<h1>{title}</h1>
				<Input label="Nombre de Usuario *" name="username" {...formik} />
				<Input label="Contraseña *" name="password" {...formik} type="password" />
				<div className="button_wrapper">
					<button type="submit">{buttonText}</button>
				</div>
			</form>
		</main>
	</div>
)
