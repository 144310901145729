import { useSelector, useDispatch } from "react-redux"

import { RootState } from "../../../../store/store"

export const useStore = (): any => {
	const { list, newGet } = useSelector((state: RootState) => state.usersReducer)
	const { list: companyList } = useSelector((state: RootState) => state.companiesReducer)
	const dispatch = useDispatch()
	const path = "users_profile/readmodel"
	const pathCompanies = "companies/readmodel"

	return { list, dispatch, path, pathCompanies, newGet, companyList }
}

export { usersReadModel, userPost, companiesReadModel } from "../../../../store/actions"
