export const clearValues = {
	businessName: "",
	user: "",
	yearExperience: "",
	nit: "",
	address: "",
	department: "",
	phone: "",
	email: "",
}
