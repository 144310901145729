import { FC } from "react"

import "./List.styles.css"
import { TListRenderProps } from "./List.types"
import {
   IconDelete,
   IconSettings,
   IconEye,
} from "../../components/commons/Icons"

export const ListRender: FC<TListRenderProps> = ({
   list,
   landing,
   settings,
   remove,
}) => (
   <main id="list">
      {list.length > 0 ? (
         <>
            <h1>Proyectos</h1>
            <table>
               <tbody>
                  {list.map((line: any) => (
                     <tr key={line._id}>
                        <td>{line.name}</td>
                        <td
                           className="right"
                           onClick={() => landing(line.id)}
                           title="Vista previa"
                        >
                           {line.landing && <IconEye />}
                        </td>
                        <td
                           className="right"
                           onClick={() => settings(line.id)}
                           title="Configurar"
                        >
                           <IconSettings />
                        </td>
                        <td
                           className="right"
                           onClick={() => remove(line.id)}
                           title="Eliminar"
                        >
                           <IconDelete />
                        </td>
                     </tr>
                  ))}
               </tbody>
            </table>
         </>
      ) : (
         <div className="empty">
            <span>😿 Aun no tienes proyectos creados...</span>
         </div>
      )}
   </main>
)
