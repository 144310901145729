import { FC } from "react"

import { Input } from "../../../../../components/commons/Fields"

export const Form: FC<any> = ({ formik }) => (
	<form onSubmit={formik.handleSubmit}>
		<input type="hidden" name="id" />
		<div>
			<div>
				<Input {...formik} name="name" label="Nombre" />
			</div>
			<div>
				<Input {...formik} name="type" label="Tipo" />
			</div>
		</div>
		<div>
			<div>
				<Input {...formik} name="price" label="Precio" type="number" />
			</div>
			<div>
				<Input {...formik} name="durationTime" label="Tiempo de duración" type="number" />
			</div>
		</div>
		<div>
			<div>
				<Input {...formik} name="priority" label="Prioridad" type="number" />
			</div>
			<div />
		</div>
		<button type="submit">Enviar</button>
	</form>
)
