import { useState } from "react"
import { FormikValues, useFormik } from "formik"
import { v4 } from "uuid"
import { toast } from "react-toastify"

import { useStore, faqPost } from "../store"
import { crudTypeT } from "../entities"
import { validationSchema } from "."
import { clearValues, toastConfig } from "../data"

export const useForm = () => {
	const [initialValues, setInitialValues] = useState<FormikValues>(clearValues)
	const [crud, setCrud] = useState<crudTypeT>("none")
	const { list, dispatch } = useStore()

	const edit = (id: string): void => {
		const question = list.find((question: { id: string }) => question.id === id)
		setInitialValues(question)
		setCrud("edit")
	}

	const create = () => {
		formik.resetForm()
		setInitialValues(clearValues)
		setCrud("create")
	}

	const onSubmit = (values: FormikValues) => {
		const { _id, lastEvent, id, ...rest } = values
		dispatch(faqPost(`frecuent-questions/${id ? id : v4()}/${id ? "edit-frecuent-questions" : "create-frecuent-question"}`, rest))
		setCrud("none")
		toast(`🦄 ${crud === "create" ? "Creaste exitosamente una" : "Editaste exitosamente la"} pregunta`, toastConfig)
	}

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		validationSchema,
		onSubmit,
	})

	return { formik, edit, create }
}
