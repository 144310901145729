import { useEffect, FC } from "react"
import { useDropzone } from "react-dropzone"

import "./ImagesUpload.styles.css"

export const ImagesUpload: FC<any> = ({ files, setFiles, text }) => {
	const { getRootProps, getInputProps } = useDropzone({
		accept: "image/*",
		onDrop: acceptedFiles => {
			setFiles((prev: File[]) => [
				...prev,
				acceptedFiles.map((file: any) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				),
			])
		},
	})

	const handleRemove = (file: File) => setFiles((prev: any) => prev.filter((filePrev: File) => filePrev !== file))

	const thumbs = files?.map((file: any, index: number) => (
		<div key={index}>
			<div>
				<div className="close" onClick={() => handleRemove(file)}>
					x
				</div>
				<img src={file[0].preview} alt={file[0].name} />
			</div>
		</div>
	))

	useEffect(() => {
		files.forEach((file: any) => URL.revokeObjectURL(file.preview))
	}, [files])

	return (
		<section className="container">
			<div {...getRootProps({ className: "dropzone" })}>
				<input {...getInputProps()} />
				<p>{text ? text : "Arrastrar y soltar imagen aquí o dar clic para seleccionar imagen."}</p>
			</div>
			<aside>{thumbs}</aside>
		</section>
	)
}
