import "../admin.css"
import { tableHeader, filterOptions, list, title } from "./data"
import { useSearchFilters } from "./userStories"
import { THead, TBody, Filters, Aside, Add } from "./components"

export const MembershipPay = () => {
	const { renderList, handleFilteredList } = useSearchFilters(list)

	return (
		<>
			<main id="admin">
				<Aside />
				<section>
					<h1>{title}</h1>
					<div>
						<div />
						<Filters handleFilteredList={handleFilteredList} options={filterOptions} />
					</div>
					<table>
						<THead data={tableHeader} />
						<TBody list={renderList} />
					</table>
				</section>
			</main>
			<Add />
		</>
	)
}
