import { useEffect } from "react"
import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { useParams, useNavigate } from "react-router-dom"
import * as Yup from "yup"

import "./ChooseTemplateType.styles.css"
import { FormStyled } from "../Project/Tabs/Form.styled"
import { Input } from "../../components/commons/Fields/Input"
import { required } from "../../utilities/errorMessages"
import { projectCreateTemplate } from "../../store/actions"
import { RootState } from "../../store/store"

const validationSchema = Yup.object().shape({
	name: Yup.string().required(required),
	type: Yup.string().required(required),
})

const initialValues = {
	name: "",
	type: "",
}

export const ChooseTemplateType = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { uuid } = useParams()

	const { list } = useSelector((state: RootState) => {
		return state.projectReducer
	})

	useEffect(() => {
		if (list[0]?.template?.name || list.state) navigate(`/create-template/${uuid}`)
	}, [list])

	const onSubmit = () => {
		dispatch(projectCreateTemplate(`projects/${uuid}/start-template`, formik.values))
	}

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		onSubmit,
		validationSchema,
	})

	return (
		<main id="page">
			<FormStyled>
				<form onSubmit={formik.handleSubmit}>
					<h1>Tipo de financiación</h1>
					<div>
						<Input label="Nombre *" name="name" {...formik} />
					</div>
					<div className="button_wrapper">
						<div>
							<button type="button" className={`inverse ${formik.values.type === "traditional"}`} onClick={() => formik.setFieldValue("type", "traditional")}>
								Tradicional
							</button>
							<button type="button" className={`inverse ${formik.values.type === "continue"}`} onClick={() => formik.setFieldValue("type", "continue")}>
								Continua
							</button>
						</div>
						{formik.errors.type && <div className="errorMessage">* Campo obligatorio</div>}
						<div>
							<button type="submit">Enviar</button>
						</div>
					</div>
				</form>
			</FormStyled>
		</main>
	)
}
