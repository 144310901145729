import { FC } from "react"

import { Input, Textarea } from ".."

export const Form: FC<any> = ({ formik }) => (
	<form onSubmit={formik.handleSubmit}>
		<div>
			<div>
				<Input {...formik} name="category" label="Categoría" />
			</div>
			<div>
				<Input {...formik} name="type" label="Tipo" />
			</div>
		</div>
		<div>
			<div>
				<Input {...formik} name="question" label="Pregunta" />
			</div>
			<div>
				<Input {...formik} name="position" label="Posición" type="number" />
			</div>
		</div>
		<div>
			<div>
				<Textarea {...formik} name="answer" label="Respuesta" />
			</div>
		</div>
		<button type="submit">Enviar</button>
	</form>
)
