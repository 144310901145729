/* eslint-disable indent */
import { Dispatch } from "redux"

import { POST_SUCCESS, POST_FAIL, POST_REQ, POST_RESET } from "../types"
import { fetchActions } from "../fetch"

export const postRequest = (): { type: typeof POST_REQ } => ({
	type: POST_REQ,
})

export const postSuccess = <A>(data: A): { type: typeof POST_SUCCESS; payload: A } => ({
	type: POST_SUCCESS,
	payload: data,
})

export const postFailure = (error: string): { type: typeof POST_FAIL; payload: string } => ({
	type: POST_FAIL,
	payload: error,
})

export const postReset = () => ({
	type: POST_RESET,
})

export const post =
	<A>(path: string, body: A): any =>
	(dispatch: Dispatch) => {
		dispatch(postRequest())
		fetchActions({
			dispatch,
			port: process.env.REACT_APP_PORT_BACKEND as string,
			path,
			success: postSuccess,
			failure: postFailure,
			method: "POST",
			body,
		})
	}

/* export const postMultiPart =
   <A>(path: string, body: A): any =>
   (dispatch: Dispatch) => {
      dispatch(postRequest())
      fetchActionsMultiPart({
         dispatch,
         path,
         success: postSuccess,
         failure: postFailure,
         body,
      })
   } */
