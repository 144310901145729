import { useState, useEffect } from "react"

export const useSearchFilters = (list: any, searchType?: string) => {
	const [renderList, setRenderList] = useState<any>([])

	useEffect(() => setRenderList(list), [list])

	const handleFilteredList = (options: any, type: string, view?: string): any => {
		if (!options.length) {
			setRenderList(list)
			return
		}

		const optionsArray = options.map(({ label }: { label: string }) => label)
		const newList = () =>
			list.filter((element: any) => {
				if ((type !== "name" && type !== "type" && type !== "department") || view === "faq") {
					return optionsArray.includes(element[type])
				} else if (type === "department") {
					return optionsArray.includes(element.departments[0].name)
				} else {
					return optionsArray.includes(element.memberships[0][type])
				}
			})
		setRenderList(newList)
	}

	const handleSearch = (term: string): any => {
		if (searchType) {
			const newList = () => list.filter((element: any) => element[searchType].toString().indexOf(term) > -1)
			setRenderList(newList)
		}
	}

	return { renderList, handleFilteredList, handleSearch }
}
