import { FC } from "react"

import { SearchPropsT } from "./entities"
import "./styles/index.css"
import { useSearch } from "./userStories"

export const Search: FC<SearchPropsT> = ({ handleSearch, placeholder }) => {
	const { term, search, reset } = useSearch(handleSearch)

	return (
		<>
			<input type="text" placeholder={placeholder} onChange={search} value={term} />
			{term && (
				<span className="closeSearch" onClick={reset}>
					x
				</span>
			)}
		</>
	)
}
