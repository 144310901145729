import { Dispatch } from "redux"

import { COMPANIES_SUCCESS, COMPANIES_FAILURE, COMPANIES_REQUEST, COMPANY_SUCCESS } from "../types"
import { fetchActions } from "../fetch"

export const companiesRequest = (): { type: typeof COMPANIES_REQUEST } => ({
	type: COMPANIES_REQUEST,
})

export const companiesSuccess = <A>(data: A): { type: typeof COMPANIES_SUCCESS; payload: A } => {
	return {
		type: COMPANIES_SUCCESS,
		payload: data,
	}
}

export const companySuccess = (): { type: typeof COMPANY_SUCCESS } => ({ type: COMPANY_SUCCESS })

export const companiesFailure = (error: string): { type: typeof COMPANIES_FAILURE; payload: string } => ({
	type: COMPANIES_FAILURE,
	payload: error,
})

export const companiesReadModel =
	(path: string): any =>
	(dispatch: Dispatch) => {
		dispatch(companiesRequest())
		fetchActions({
			dispatch,
			port: process.env.REACT_APP_PORT_BACKOFFICE as string,
			path,
			success: companiesSuccess,
			failure: companiesFailure,
			method: "get",
		})
	}

export const companiesPost =
	(path: string, body: any): any =>
	(dispatch: Dispatch) => {
		dispatch(companiesRequest())
		fetchActions({
			dispatch,
			port: process.env.REACT_APP_PORT_BACKOFFICE as string,
			path,
			success: companySuccess,
			failure: companiesFailure,
			method: "post",
			body,
		})
	}

export const companiesEditMembership =
	(path: string, body: any): any =>
	(dispatch: Dispatch) => {
		dispatch(companiesRequest())
		fetchActions({
			dispatch,
			port: process.env.REACT_APP_PORT_BACKOFFICE as string,
			path,
			success: companySuccess,
			failure: companiesFailure,
			method: "post",
			body,
		})
	}
