import { FC, useState, useEffect } from "react"
import { FormikValues, useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { FormStyled } from "../Form.styled"
import { ParametersValidationSchema as validationSchema } from "./utilities/Parameters.validations"
import { RootState } from "../../../../store/store"
import { post, postReset, projectGet } from "../../../../store/actions"
import { toastConfig } from "../../../../data"
import { ParametersDataFields as fields } from "./Parameters.dataFields"
import { InputMini } from "../../../../components/commons/Fields"

export const Parameters: FC<{ newId: (id: number) => void }> = ({ newId }) => {
	const [initialValues, setInitialValues] = useState<FormikValues>({})
	const { uuid: uuidByGet } = useParams()
	const dispatch = useDispatch()

	const { list } = useSelector((state: RootState) => {
		return state.projectReducer
	})

	const { result } = useSelector((state: RootState) => {
		return state.postReducer
	})

	useEffect(() => {
		if (uuidByGet) dispatch(projectGet(`projects/readmodel/${uuidByGet}`))
	}, [dispatch, uuidByGet])

	useEffect(() => {
		if (list) {
			setInitialValues({
				count: list[0].template?.count || 0,
				percentage: list[0].template?.percentage || 0,
				min: list[0].template?.min || 0,
			})
		}
	}, [list])

	useEffect(() => {
		if (result) {
			toast("🦄 Actualizaste tus parámetros", toastConfig)
			dispatch(postReset())
		}
	}, [result, dispatch])

	const handleBack = () => newId(1)

	const onSubmit = (values: FormikValues) => {
		if (JSON.stringify(values) === JSON.stringify(list.state?.template)) toast("😜 Debes cambiar algún valor!", toastConfig)
		else dispatch(post(`projects/${uuidByGet}/create-template`, values))
	}

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		onSubmit,
		validationSchema,
	})

	return (
		<>
			<FormStyled>
				<form onSubmit={formik.handleSubmit}>
					<div>
						<h2>Parámetros</h2>
					</div>
					{fields.map((field: { name: string; label: string }) => (
						<div style={{ justifyContent: "flex-start" }}>
							<InputMini label={field.label} name={field.name} type="number" {...formik} />
						</div>
					))}
					<div className="button_wrapper">
						<button type="button" className="green" onClick={handleBack}>
							Atrás
						</button>
						<button type="submit">Guardar</button>
					</div>
				</form>
			</FormStyled>
			<ToastContainer />
		</>
	)
}
