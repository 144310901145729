import { useState, useEffect } from "react"
import { useSelector } from "react-redux"

import { TabsStyled } from "./Tabs/Tabs.styled"
import "./Project.styles.css"
import { ProjectTab, PricesList, Parameters, Subsidies } from "./Tabs"
import { RootState } from "../../store/store"

export const Project = () => {
	const [tabActive, setTabActive] = useState<number>(0)
	const [disabledTabs, setDisabledTabs] = useState<number[]>([])

	const { list } = useSelector((state: RootState) => {
		return state.projectReducer
	})

	useEffect(() => {
		if (!list.length) setDisabledTabs([1, 2, 3])
		else {
			if (!list[0].priceList) setDisabledTabs([2, 3])
			else {
				if (!list[0].template) setDisabledTabs([3])
				else setDisabledTabs([])
			}
		}
	}, [list])

	console.log(list)

	const handleTabActive = (index: number): void => {
		const isDisabledIndex = disabledTabs.some((tab: number) => tab === index)
		if (!isDisabledIndex) setTabActive(index)
	}

	return (
		<main id="project-page">
			<TabsStyled>
				<div className="header">
					<div className={tabActive === 0 ? "active" : ""} onClick={() => handleTabActive(0)}>
						Proyecto
					</div>
					<div className={tabActive === 1 ? "active" : ""} onClick={() => handleTabActive(1)}>
						Lista de precios
					</div>
					{list[0]?.subsideEnabled && (
						<div className={tabActive === 2 ? "active" : ""} onClick={() => handleTabActive(2)}>
							Subsidios
						</div>
					)}
					<div className={tabActive === 3 ? "active" : ""} onClick={() => handleTabActive(3)}>
						Parámetros
					</div>
				</div>
			</TabsStyled>
			{tabActive === 0 && <ProjectTab newId={(id: number) => setTabActive(id)} />}
			{tabActive === 1 && <PricesList newId={(id: number) => setTabActive(id)} />}
			{tabActive === 2 && <Subsidies newId={(id: number) => setTabActive(id)} />}
			{tabActive === 3 && <Parameters newId={(id: number) => setTabActive(id)} />}
		</main>
	)
}
