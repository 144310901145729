import { FC } from "react"

import { TElementList } from "../../entities"
import { IconEdit } from "../../../../../../components/commons/Icons"

export const TableBody: FC<any> = ({ list, edit }) => {
	const bodyMap = () => {
		return list?.map((row: TElementList) => {
			console.log(row)
			return (
				<tr key={row.id}>
					<td>{row.id}</td>
					<td>{row.type}</td>
					<td>{row.description}</td>
					<td className="right" title="Editar" onClick={() => edit(row.id)}>
						<IconEdit />
					</td>
				</tr>
			)
		})
	}

	return <tbody>{bodyMap()}</tbody>
}
