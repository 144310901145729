import { TList } from "../entities"

export const list: TList = [
   {
      id: 1,
      company: "Aranda",
      membership: "gold",
      auto_renovation: "si",
      user: "Pepe",
      price: 10000,
      status: "Pagó",
      iva: 100,
      date: "22/06/2023",
   },
   {
      id: 2,
      company: "Conet",
      membership: "silver",
      auto_renovation: "no",
      user: "Pepe 2",
      price: 100007,
      status: "No Pagó",
      iva: 100,
      date: "22/01/2023",
   },
   {
      id: 3,
      company: "Alkemy",
      membership: "free",
      auto_renovation: "no",
      user: "Pepe 3",
      price: 0,
      status: "free",
      iva: 0,
      date: "22/01/2023",
   },
]
