import { useNavigate, useLocation } from "react-router-dom"

import "./Header.styles.css"

export const Header = () => {
	const navigate = useNavigate()
	const { pathname } = useLocation()

	const handleLogout = () => {
		localStorage.clear()
		navigate("/")
	}

	const handleHome = () => navigate("/list")
	const handleNewProject = () => navigate("/project")
	const handleAdmin = () => navigate("/companies")

	return (
		<header>
			<div>
				<img src="/img/conet_logo.svg" alt="Logo Conet" />
				<h1 onClick={handleHome}>Business</h1>
			</div>
			<div>
				<div className="links" onClick={handleAdmin}>
					Administrador
				</div>
				{pathname !== "/list" && (
					<div className="links" onClick={handleHome}>
						Nuestros proyectos
					</div>
				)}
				{pathname === "/list" && (
					<div className="links" onClick={handleNewProject}>
						Crear proyecto
					</div>
				)}
				<div className="username">{localStorage.getItem("username")}</div>
				<div className="rounded">{localStorage.getItem("username")?.charAt(0)}</div>
				<div className="logout" onClick={handleLogout}>
					x
				</div>
			</div>
		</header>
	)
}
