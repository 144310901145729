import { FC, useEffect, useState } from "react"

import { Input, Select } from ".."
import { useStore } from "../../store"

export const Form: FC<any> = ({ formik, disabled }) => {
	const [departments, setDepartments] = useState<string[]>([])
	const [cities, setCities] = useState<string[]>([])
	const { getCountries } = useStore()

	const countries = () => getCountries.map(({ name }: { name: string }) => name)

	useEffect(() => {
		if (formik.values.country) {
			const departmentsChosen = getCountries.find(({ name }: { name: string }) => name === formik.values.country).departments
			setDepartments(departmentsChosen.map(({ name }: { name: string }) => name))
		}
	}, [formik.values.country])

	useEffect(() => {
		if (formik.values.department) {
			const departments = getCountries.find(({ name }: { name: string }) => name === formik.values.country).departments
			const citiesChosen = departments.find(({ name }: { name: string }) => name === formik.values.department).cities
			setCities(citiesChosen.map(({ name }: { name: string }) => name))
		}
	}, [formik.values.department])

	if (!getCountries) return null

	return (
		<form onSubmit={formik.handleSubmit}>
			<h4>Datos usuario</h4>
			<div>
				<div>
					<Input {...formik} name="name" label="Nombre" disabled={disabled} />
				</div>
				<div>
					<Input {...formik} name="lastName" label="Apellido" disabled={disabled} />
				</div>
			</div>
			<div>
				<div>
					<Input {...formik} name="userEmail" label="Email" type="email" disabled={disabled} />
				</div>
				<div>
					<Input {...formik} name="password" label="Contraseña" type="password" disabled={disabled} />
				</div>
			</div>
			<h4>Datos empresa</h4>
			<div>
				<div>
					<Input {...formik} name="businessName" label="Razón social" disabled={disabled} />
				</div>
				<div>
					<Input {...formik} type="number" name="yearExperience" label="Años de experiencia" disabled={disabled} />
				</div>
				<div>
					<Input {...formik} name="nit" label="NIT" type="number" disabled={disabled} />
				</div>
			</div>
			<div>
				<div>
					<Input {...formik} name="address" label="Dirección" disabled={disabled} />
				</div>
				<div>
					<Select {...formik} name="country" label="País" disabled={disabled} options={countries()} />
				</div>
			</div>
			{formik.values.country && (
				<div>
					<div>
						<Select {...formik} name="department" label="Departamento" disabled={disabled} options={departments} />
					</div>
					{formik.values.department && (
						<div>
							<Select {...formik} name="city" label="Ciudad" disabled={disabled} options={cities} />
						</div>
					)}
				</div>
			)}
			<div>
				<div>
					<Input {...formik} name="phone" label="Teléfono" disabled={disabled} />
				</div>
				<div>
					<Input {...formik} name="email" label="Correo electrónico" disabled={disabled} />
				</div>
			</div>
			{!disabled && <button type="submit">Enviar</button>}
		</form>
	)
}
