import { FC } from "react"

export const Textarea: FC<any> = ({
   errors,
   touched,
   handleBlur,
   handleChange,
   label,
   name,
   values,
}) => (
   <>
      <label>{label}</label>
      <textarea
         name={name}
         onChange={handleChange}
         onBlur={handleBlur}
         className={errors[name] && touched[name] && "error"}
         value={values[name]}
      />
      {errors[name] && touched[name] && (
         <div className="errorMessage">{errors[name]}</div>
      )}
   </>
)
