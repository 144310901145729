import { Routes, Route, Navigate, useLocation } from "react-router-dom"
import { AnimatePresence } from "framer-motion"

import "./App.css"
import {
	Header,
	PageTransition,
	Login,
	Register,
	Companies,
	UsersProfilesBackOffice,
	UsersProfilesCompanies,
	MembershipPay,
	Error404,
	List,
	Project,
	Landing,
	Faq,
	MembershipSettings,
	CompaniesMembership,
	ServicesTypes,
	ChooseTemplateType,
	CreateTemplate,
} from "./views"

const Auth = ({ children, inverse }: { children: any; inverse?: boolean }) => {
	if (inverse) {
		if (localStorage.getItem("token")) {
			return <Navigate to="/project" replace={true} />
		}
		return children
	} else {
		if (!localStorage.getItem("token")) {
			return <Navigate to="/" replace={true} />
		}
		return children
	}
}

export const App = () => {
	const location = useLocation()

	return (
		<AnimatePresence exitBeforeEnter>
			<Routes location={location} key={location.pathname}>
				<Route
					path="/"
					element={
						<Auth inverse={true}>
							<PageTransition>
								<Login />
							</PageTransition>
						</Auth>
					}
				/>
				<Route
					path="/register"
					element={
						<Auth inverse={true}>
							<PageTransition>
								<Register />
							</PageTransition>
						</Auth>
					}
				/>
				<Route
					path="/list"
					element={
						<Auth>
							<PageTransition>
								<>
									<Header />
									<List />
								</>
							</PageTransition>
						</Auth>
					}
				/>
				<Route
					path="/companies"
					element={
						<Auth>
							<PageTransition>
								<>
									<Header />
									<Companies />
								</>
							</PageTransition>
						</Auth>
					}
				/>
				<Route
					path="/users-profiles-back-office"
					element={
						<Auth>
							<PageTransition>
								<>
									<Header />
									<UsersProfilesBackOffice />
								</>
							</PageTransition>
						</Auth>
					}
				/>
				<Route
					path="/users-profiles-companies"
					element={
						<Auth>
							<PageTransition>
								<>
									<Header />
									<UsersProfilesCompanies />
								</>
							</PageTransition>
						</Auth>
					}
				/>
				<Route
					path="/faq"
					element={
						<Auth>
							<PageTransition>
								<>
									<Header />
									<Faq />
								</>
							</PageTransition>
						</Auth>
					}
				/>
				<Route
					path="/services-types-settings"
					element={
						<Auth>
							<PageTransition>
								<>
									<Header />
									<ServicesTypes />
								</>
							</PageTransition>
						</Auth>
					}
				/>
				<Route
					path="/membership-settings"
					element={
						<Auth>
							<PageTransition>
								<>
									<Header />
									<MembershipSettings />
								</>
							</PageTransition>
						</Auth>
					}
				/>
				<Route
					path="/companies-membership"
					element={
						<Auth>
							<PageTransition>
								<>
									<Header />
									<CompaniesMembership />
								</>
							</PageTransition>
						</Auth>
					}
				/>
				<Route
					path="/membership-pay"
					element={
						<Auth>
							<PageTransition>
								<>
									<Header />
									<MembershipPay />
								</>
							</PageTransition>
						</Auth>
					}
				/>
				<Route
					path="/project/:uuid"
					element={
						<Auth>
							<Header />
							<Project />
						</Auth>
					}
				/>
				<Route
					path="/project"
					element={
						<Auth>
							<PageTransition>
								<>
									<Header />
									<Project />
								</>
							</PageTransition>
						</Auth>
					}
				/>
				<Route
					path="/new-template/:uuid"
					element={
						<Auth>
							<PageTransition>
								<>
									<Header />
									<ChooseTemplateType />
								</>
							</PageTransition>
						</Auth>
					}
				/>
				<Route
					path="/create-template/:uuid"
					element={
						<Auth>
							<PageTransition>
								<>
									<Header />
									<CreateTemplate />
								</>
							</PageTransition>
						</Auth>
					}
				/>
				<Route path="/land/:uuid" element={<Landing />} />
				<Route
					path="*"
					element={
						<PageTransition>
							<>
								<Error404 />
							</>
						</PageTransition>
					}
				/>
			</Routes>
		</AnimatePresence>
	)
}
