import { FC, useState } from "react"
import { useDrop } from "react-dnd"

import { ItemTypes } from "./ItemTypes"
import { ImagesUpload, IconChevron, IconCheck } from "./components"
import SortableList, { SortableItem } from "react-easy-sort"

export const Dustbin: FC<{ tasks: { name: string; default: boolean; uuid: string }[]; undropped: (item: string) => void; onSortEnd: (oldIndex: number, newIndex: number) => void }> = ({
	tasks,
	undropped,
	onSortEnd,
}) => {
	const [toggle, setToggle] = useState<string[]>([])
	const [files, setFiles] = useState<any>([])

	const [{ canDrop, isOver }, drop] = useDrop(() => ({
		accept: ItemTypes.BOX,
		drop: () => ({ name: "Dustbin" }),
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
	}))

	const isActive = canDrop && isOver

	const handleToggle = (uuid: string) => {
		if (toggle.indexOf(uuid) === -1) setToggle((prev: string[]) => [...prev, uuid])
		else {
			const [key, ...rest] = toggle
			setToggle(rest)
		}
	}

	return (
		<div ref={drop}>
			<div className="extra">
				<IconCheck />
				Solicitud de documento de identidad
			</div>
			<SortableList onSortEnd={onSortEnd} draggedItemClassName="dragged">
				{tasks.map((task: { name: string; default: boolean; uuid: string }) => (
					<SortableItem key={task.uuid}>
						<div {...(toggle.indexOf(task.uuid) > -1 ? { class: "open" } : {})}>
							<IconChevron onClick={() => handleToggle(task.uuid)} />
							{task.name}
							{!task.default && <span onClick={() => undropped(task.uuid)}>x</span>}
							{toggle.indexOf(task.uuid) > -1 && (
								<div className="body">
									{task.name === "Firma de contrato" && <ImagesUpload text="Subir archivos" files={files} setFiles={setFiles} />}
									{task.name === "Solicitud de documentos presencial" && (
										<div className="change-name-wrapper">
											<input name="change-name-input" type="text" value="" />
											<button type="button" className="change-name-button">
												Agregar
											</button>
										</div>
									)}
									{task.name === "Solicitud de documentos virtual" && (
										<div className="change-name-wrapper">
											<input name="change-name-input" type="text" value="" />
											<button type="button" className="change-name-button">
												Agregar
											</button>
										</div>
									)}
								</div>
							)}
						</div>
					</SortableItem>
				))}
				<div className="extra">
					<IconCheck />
					Entrega
				</div>
			</SortableList>
		</div>
	)
}
