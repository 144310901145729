import { Dispatch } from "redux"

import { LOGIN_REQ, LOGIN_SUCCESS, LOGIN_FAIL, LOGIN_RESET } from "../types"
import { fetchActions } from "../fetch"

const { REACT_APP_PORT_BACKOFFICE } = process.env

export const loginReq = () => ({ type: LOGIN_REQ })

export const loginSuccess = <A>(data: A) => ({
	type: LOGIN_SUCCESS,
	payload: data,
})

export const loginFail = (error: any) => ({
	type: LOGIN_FAIL,
	payload: error,
})

export const loginReset = () => ({ type: LOGIN_RESET })

export const login = (username: string, password: string) => (dispatch: Dispatch) => {
	dispatch(loginReq())
	fetchActions({
		dispatch,
		port: REACT_APP_PORT_BACKOFFICE as string,
		path: "login",
		success: loginSuccess,
		failure: loginFail,
		method: "POST",
		body: { username, password },
	})
}
