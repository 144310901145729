import { POST_FAIL, POST_REQ, POST_SUCCESS, POST_RESET } from "../types"

const initialState = {
   loading: false,
   post: [],
   error: "",
}

export const postReducer = <A>(
   state = initialState,
   action: {
      type:
         | typeof POST_FAIL
         | typeof POST_REQ
         | typeof POST_SUCCESS
         | typeof POST_RESET
      payload: A
   }
): any => {
   switch (action.type) {
      case POST_REQ: {
         return {
            ...state,
            loading: true,
         }
      }
      case POST_SUCCESS: {
         return {
            loading: false,
            result: action.payload,
            error: "",
         }
      }
      case POST_FAIL: {
         return {
            ...state,
            loading: false,
            error: action.payload,
         }
      }
      case POST_RESET: {
         return initialState
      }
      default:
         return state
   }
}
