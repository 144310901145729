import { FC } from "react"

import { Input, Select } from ".."

export const Form: FC<any> = ({ formik, list }) => (
	<form onSubmit={formik.handleSubmit}>
		<div>
			<div>
				<Select {...formik} name="company" label="Asociar empresa" options={list.map((company: { businessName: string }) => company.businessName)} />
			</div>
			<div>
				<Input {...formik} name="username" label="Nombre" />
			</div>
		</div>
		<div>
			<div>
				<Input {...formik} name="email" typw="email" label="Email" />
			</div>
			<div>
				<Input {...formik} name="rol" label="rol" />
			</div>
		</div>
		<button type="submit">Enviar</button>
	</form>
)
